import { useEffect, useMemo, useState } from "react";
import usePalette from "../../../hooks/usePalette";
import dayjs, { Dayjs } from "dayjs";
import Box from "../../../design/components-dev/BoxExtended";
import { getSizing } from "../../../design/sizing";
import XNGInput from "../../../design/low-level/input";
import { useNotatorTools } from "../tools";
import { AddRationaleModal } from "../modals/add_rationale";
import useBreakpointHelper from "../../../design/hooks/use_breakpoint_helper";
import { timezoneAdjustedStartOrEndTimes } from "../../../utils/timeZones";
import { useXNGSelector } from "../../../context/store";
import { selectStateInUS } from "../../../context/slices/stateInUsSlice";
import { produce } from "immer";
import { useAsyncComputeOnChange } from "../hooks/use_async_compute_on_change";
import { Checkbox, FormControlLabel, IconButton, SxProps, TextField, Tooltip, Typography, useMediaQuery, useTheme } from "@mui/material";
import AutoBreakpointNotatorLayoutTwoColumns from "../layouts/tab_inner_viewport_layout_two_sections";
import { TimePicker } from "@mui/x-date-pickers";
import useEffectSkipMount from "../../../hooks/use_effect_after_mount";
import { ServiceAnalysisView } from "./session_times/service_analysis";
import { selectFeatureFlags } from "../../../context/slices/featureFlagsSlice";
import { NotatorTabViewPropsBase } from "../types/tab_view_props_base"; 
import { PERSONAL_CARE_SERVICES_NAME } from "../validation/constants";
import { ScheduleOfService, StudentProfile } from "../../../profile-sdk";
import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent"
import MSBSemiCirclePieChart from "../../../fortitude/amcharts/semi_circle_pie_chart";
import useSessionTime from "../hooks/useSessionTime";
import { MSBICONS } from "../../../fortitude";
import { XNGIconRenderer } from "../../../design"; 
import useSemiCircleChartData from "../hooks/use_semi_circle_chart_clr";
import useCheckSessionInRevisionStatusAndUserIsApprover from "./observations/hooks/use_check_if_revision_session_and_is_approver";

// This is purely a presentational, or "dumb" component. This is not to house any of its own state. It should only ever provide callbacks.
// See more:
// https://medium.com/@dan_abramov/smart-and-dumb-components-7ca2f9a7c7d0
// https://medium.com/@thejasonfile/dumb-components-and-smart-components-e7b33a698d43

interface SessionTimesTabView extends NotatorTabViewPropsBase {
  isAllStudentView?: boolean;
}

export default function SessionTimesTabView(props: Readonly<SessionTimesTabView>) {
  // ---- Hooks ----
  const notatorTools = useNotatorTools();
  const { readOnly, layoutControls: { layoutMode } } = notatorTools;
  const { draftSession: editedSession, editDraft: editSession, cachedResponsesByStudentIndex, isSessionFinalized } = notatorTools;
  const palette = usePalette();
  const isUserApproverAndSessionInRevisionStatus = useCheckSessionInRevisionStatusAndUserIsApprover()
  const userStateInUS = useXNGSelector(selectStateInUS);
  // ---- States ----
  const [showRationaleModal, setShowRationaleModal] = useState<boolean>(false); 
  //@ts-ignore
  const [supportServiceMinutes,setSupportServiceMinutes] = useState<number|null>(editedSession.studentJournalList[props.selectedStudentIndex].studentAttendanceRecord.serviceSupportMinutes || 0)

  useEffect(() => {
    setSupportServiceMinutes(editedSession.studentJournalList?.[props.selectedStudentIndex].studentAttendanceRecord?.serviceSupportMinutes || 0)
  }, [props.selectedStudentIndex])

  const { timezoneAdjustedStartTime, timezoneAdjustedEndTime } = timezoneAdjustedStartOrEndTimes(
    userStateInUS,
    "display",
    dayjs(
      editedSession.studentJournalList![props.selectedStudentIndex].studentAttendanceRecord
        ?.arrivalTime
        ? editedSession.studentJournalList![props.selectedStudentIndex].studentAttendanceRecord
            ?.arrivalTime
        : editedSession.meetingDetails?.startTime,
    ) ?? null,
    dayjs(
      editedSession.studentJournalList![props.selectedStudentIndex].studentAttendanceRecord
        ?.departureTime
        ? editedSession.studentJournalList![props.selectedStudentIndex].studentAttendanceRecord
            ?.departureTime
        : editedSession.meetingDetails?.endTime,
    ) ?? null,
  );
  const draftShortcutConstants = {
    startTime: timezoneAdjustedStartTime,
    endTime: timezoneAdjustedEndTime,
    timeAway:
      editedSession.studentJournalList![props.selectedStudentIndex].studentAttendanceRecord
        ?.timeAwayMinutes,
    rationale:
      editedSession.studentJournalList?.[props.selectedStudentIndex].studentAttendanceRecord
        ?.rationale,
  };

  const draftSessionTimesAdapter = {
    scheduleMinutes: dayjs(draftShortcutConstants.endTime).diff(
      // can we get an explanation of what this is?
      draftShortcutConstants.startTime,
      "minutes",
    ),
    studentName: `${editedSession.studentJournalList?.[props.selectedStudentIndex].student
      ?.firstName} ${editedSession.studentJournalList?.[props.selectedStudentIndex].student
      ?.lastName}`,
  };

  const [clickable, setClickable] = useState<boolean>(false);

  useEffect(() => {
    if (editedSession.status === 4 || editedSession.status === 5) {
      setClickable(true);
    } else {
      setClickable(false);
    }
  }, [editedSession.status]);

  const { isApplySessionTimesChecked, toggleApplySessionTimesCheckbox } =
    useIsApplySessionTimesChecked({
      onChange: () => {
        const startTime = notatorTools.draftSession.meetingDetails!.startTime;
        const endTime = notatorTools.draftSession.meetingDetails!.endTime;

        setStartDate(startTime ? dayjs(startTime) : null);
        setEndDate(endTime ? dayjs(endTime) : null);
      },
      selectedStudentIndex: props.selectedStudentIndex
    });

  const [startDate, setStartDate] = useAsyncComputeOnChange<Dayjs | null>({
    defaultValue: dayjs(
      notatorTools.draftSession.studentJournalList![props.selectedStudentIndex]
        .studentAttendanceRecord!.arrivalTime,
    ),
    onChange: () => {
      setTimeout(() => {
        notatorTools.setDraftSession(
          produce((draft) => {
            draft.studentJournalList![
              props.selectedStudentIndex
            ].studentAttendanceRecord!.arrivalTime = startDate ? startDate.toDate() : undefined;
          }),
        );
      }, 5);
    },
  });
  const [endDate, setEndDate] = useAsyncComputeOnChange<Dayjs | null>({
    defaultValue: dayjs(
      notatorTools.draftSession.studentJournalList![props.selectedStudentIndex]
        .studentAttendanceRecord!.departureTime,
    ),
    onChange: () => {
      setTimeout(() => {
        notatorTools.setDraftSession(
          produce((draft) => {
            draft.studentJournalList![
              props.selectedStudentIndex
            ].studentAttendanceRecord!.departureTime = endDate ? endDate.toDate() : undefined;
          }),
        );
      }, 5);
    },
  });

  const studentProfile: StudentProfile | null = useMemo(() => {
    if (!cachedResponsesByStudentIndex) return null;

    return cachedResponsesByStudentIndex[props.selectedStudentIndex]?.studentProfile ?? null;
  }, [cachedResponsesByStudentIndex]);

  const studentScheduleOfServiceOptions: ScheduleOfService[] = useMemo(
    () => studentProfile?.spedDossier?.scheduleOfServices ?? [],
    [studentProfile],
  );

  const serviceAnalysisViewFeatureActive = useXNGSelector((state) =>
    selectFeatureFlags(state, "ServiceAnalysisViewActive"),
  );
  const theme = useTheme();
  const {Information} = MSBICONS

  const leftRightColumnsSxProps: { left: SxProps; right: SxProps } = useMemo(() => {
    if(!serviceAnalysisViewFeatureActive || studentScheduleOfServiceOptions.length === 0) {
      return { left: { minWidth: "100%" }, right: { maxWidth: "0%", overflow: "hidden" } };
    }

    return { left: {}, right: {} };
  }, [serviceAnalysisViewFeatureActive, studentScheduleOfServiceOptions]);

  const leftRightColumnsSxPropsForPersonalCareSession = useMemo(() => {
    return { left: { maxWidth: "40%" }, right: { minWidth: "600px",display:"flex",flexDirection:"row",alignItems:"flex-end" } };
  },[])

  const isSmallScreen = useMediaQuery("(max-width: 1200px)");
 

  const memoisedTotalBillableMinutes = useMemo(() => editedSession?.studentJournalList?.[props.selectedStudentIndex]?.careProvisionLedger?.activities?.reduce((total, activity) => total + (Number(activity.totalMinutesSpent ?? 0)), 0), [editedSession,props.selectedStudentIndex]);
  const sessionDate = dayjs(notatorTools.session.meetingDetails?.startTime); // Assuming notatorTools.session.meetingDetails.startTime contains the session date
  const cutoffDate = dayjs('2024-10-01');
  const isPersonalCareSession = notatorTools.session.service?.area?.name?.trim().toLocaleLowerCase() === PERSONAL_CARE_SERVICES_NAME.trim().toLocaleLowerCase() && (sessionDate.toDate() >= cutoffDate.toDate()); // Compare dates directly
  const sessionMinutes = useSessionTime()

  const getNetDuration =()=>{
    if(draftSessionTimesAdapter.scheduleMinutes){
      return draftSessionTimesAdapter.scheduleMinutes - draftShortcutConstants.timeAway!
    }else{
      return 60 - draftShortcutConstants.timeAway!
    } 
  }

  const handleSupportServiceMinuteChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // Remove any non-numeric characters
    const numericValue = event.target.value.replace(/\D/g, '');
  
    if (numericValue === '') {
      setSupportServiceMinutes(null);
      return;
    }
  
    const value = Number(numericValue);
    setSupportServiceMinutes(value); 
    event.target.value = numericValue;
  };

  const isPersonalCareSessionAndIsDocumentation = isPersonalCareSession && layoutMode === "Documentation View"

  const chartData = useSemiCircleChartData(memoisedTotalBillableMinutes, sessionMinutes, supportServiceMinutes as number)
  
  function chartCallback(chart: am5percent.PieChart, root: am5.Root) {
        const centerContainer = chart.seriesContainer.children.push(
          am5.Container.new(root, {
            centerX: am5.percent(50), // Center horizontally
            centerY: am5.percent(90), // Adjust vertically for semi-circle
            layout: root.verticalLayout,
          })
        );

       centerContainer.children.push(
          am5.Label.new(root, {
            text: `${sessionMinutes}`, // Dynamic number
            fontSize: 30, // Larger font size for the number
            textAlign: "center",
            x: am5.p50, // Center horizontally
            fill: am5.color(0x000000),
          })
        );
        
        // Add a label for the smaller text
       centerContainer.children.push(
          am5.Label.new(root, {
            text: "Session Minutes",
            fontSize: 20, // Default font size for the text
            textAlign: "center",
            x: am5.p50, // Center horizontally
            fill: am5.color(0x000000),
          })
        );
  }
  return (
    <>
      <AutoBreakpointNotatorLayoutTwoColumns
        leftConfig={{
          title: "Today's Session Times",
          useLink: {
            onClick: () => setShowRationaleModal(!showRationaleModal),
            text: draftShortcutConstants.rationale ? "View Rationale" : "Add Rationale",
            disabled: isUserApproverAndSessionInRevisionStatus
          },
          sx: isPersonalCareSessionAndIsDocumentation ? leftRightColumnsSxPropsForPersonalCareSession.left : leftRightColumnsSxProps.left,
        }}
        left={
          <>
            <FormControlLabel
              disabled={notatorTools.isSessionFinalized || isUserApproverAndSessionInRevisionStatus}
              control={
                <Checkbox
                  size="small"
                  checked={isApplySessionTimesChecked}
                  onChange={toggleApplySessionTimesCheckbox}
                />
              }
              label={<Typography variant="body1">Apply Session Times</Typography>}
            />
            <Box
              sx={{
                display: "flex",
                gap: getSizing(5),
                paddingTop: getSizing(3),
                paddingBottom: getSizing(3),
                ...(clickable === true && { pointerEvents: "none" }),
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  float: "left",
                  gap: getSizing(3),
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="body1">Start Time: </Typography>
                  <TimePicker
                    value={dayjs(draftShortcutConstants.startTime)}
                    onChange={(v: Dayjs | null) => setStartDate(v)}
                    disabled={readOnly || isApplySessionTimesChecked}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="body1">End Time: </Typography>
                  <TimePicker
                    value={dayjs(draftShortcutConstants.endTime)}
                    onChange={(v: Dayjs | null) => setEndDate(v)}
                    disabled={readOnly || isApplySessionTimesChecked}
                  />
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    bgcolor: palette.contrasts[3],
                    height: "1px",
                    marginY: getSizing(2),
                  }}
                />
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography variant="body1">Schedule Minutes</Typography>
                  <Typography variant="body1">
                    {draftSessionTimesAdapter.scheduleMinutes
                      ? draftSessionTimesAdapter.scheduleMinutes
                      : 60}
                  </Typography>
                </Box>
              {!isPersonalCareSession && 
              (<Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography variant="body1">Time away from student</Typography>
                  <Box sx={{ display: "flex", width: getSizing(7) }}>
                    <XNGInput
                      type="number"
                      value={draftShortcutConstants.timeAway?.toString()}
                      onChange={(e) => {
                        if (e.target.value === "") {
                          editSession(
                            `studentJournalList.${props.selectedStudentIndex}.studentAttendanceRecord.timeAwayMinutes`,
                            0,
                          );
                          return;
                        }
                        if (parseInt(e.target.value) < 0) {
                          editSession(
                            `studentJournalList.${props.selectedStudentIndex}.studentAttendanceRecord.timeAwayMinutes`,
                            0,
                          );
                          return;
                        }
                        if (parseInt(e.target.value) > draftSessionTimesAdapter.scheduleMinutes) {
                          editSession(
                            `studentJournalList.${props.selectedStudentIndex}.studentAttendanceRecord.timeAwayMinutes`,
                            draftSessionTimesAdapter.scheduleMinutes,
                          );
                          return;
                        }
                        editSession(
                          `studentJournalList.${props.selectedStudentIndex}.studentAttendanceRecord.timeAwayMinutes`,
                          parseInt(e.target.value),
                        );
                      }}
                      disabled={readOnly}
                      maxNumber={draftSessionTimesAdapter.scheduleMinutes}
                    />
                  </Box>
              </Box>)
              }
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography variant="body1">
                    {isPersonalCareSession ? "Total Billable Minutes" : "Net Duration"}
                  </Typography>
                  <Typography variant="body1">
                    {isPersonalCareSession ? memoisedTotalBillableMinutes : getNetDuration()}
                  </Typography>
                </Box>

                {isPersonalCareSession && 
                ( <Box sx={{ display: "flex", justifyContent: "start", alignItems: "center" }}>
                  <Typography variant="body1">
                    Support Service Minutes
                  </Typography>
                  <TextField
                    variant="outlined"
                    type="number"
                    value={supportServiceMinutes}
                    onChange={handleSupportServiceMinuteChange}
                    onBlur={()=>{
                      notatorTools.setDraftSession(
                        produce((draft) => {
                          draft.studentJournalList![
                            props.selectedStudentIndex
                          ].studentAttendanceRecord!.serviceSupportMinutes = supportServiceMinutes ?? 0;
                        }),
                      );
                    }}
                    disabled = {readOnly}
                    defaultValue={0}
                    sx={{
                      width: "80px",
                      height: "40px",
                      ml: 10,
                      "& .MuiOutlinedInput-root": {
                        height: "40px",
                      },
                      "& .MuiInputBase-input": {
                        height: "40px",
                        padding: "8px", // Adjust padding to align text properly
                      },
                    }}
                  />
                  <Tooltip
                    title={`The “Support Service Minutes” represent all service minutes provided during this session that are not directly tied to a billable activity`}
                    
                  >
                    <Box pl={3} sx={{ cursor: "pointer",display:"block" }}> 
                      <IconButton sx={{ p: 0, width: "20px", height: "20px" }}>
                          <XNGIconRenderer
                            i={<Information />}
                            size="sm"
                            color={theme.palette.primary.main}
                          /> 
                      </IconButton>
                    </Box>
                  </Tooltip>
                </Box>)}
              </Box>
            </Box>
          </>
        }
        right={
        <> 
          <ServiceAnalysisView visibleStudentIndex={props.selectedStudentIndex} />
          {isPersonalCareSessionAndIsDocumentation && 
          <Box sx={{ width: "400px",marginLeft: isSmallScreen ? "0px" : "150px" ,overflow: "visible",display: "flex",flexDirection: "column", alignItems: "center",justifyContent:"flex-end" }}>
            {/* height and width should be in 1:3 / 1:4 */}
            <MSBSemiCirclePieChart 
             height={"180px"}
             width= {"700px"}
             callback={chartCallback}
             data={chartData} />

            </Box>}
        
        </>
      }
        rightConfig={{ title: "Service Analysis", sx:isPersonalCareSessionAndIsDocumentation ? leftRightColumnsSxPropsForPersonalCareSession.left : leftRightColumnsSxProps.right }}
      />

      <AddRationaleModal
        selectedStudentIndex={props.selectedStudentIndex}
        setModalOpen={setShowRationaleModal}
        modalOpen={showRationaleModal}
        rationale={draftShortcutConstants.rationale}
        studentName={draftSessionTimesAdapter.studentName}
        disableInputs={isSessionFinalized}
      />
    </>
  );
}

function useIsApplySessionTimesChecked(props: { onChange: () => void, selectedStudentIndex: number }) {
  const { draftSession, setDraftSession } = useNotatorTools();
  const { selectedStudentIndex } = props
  const [isApplySessionTimesChecked, setIsApplySessionTimesChecked] = useState<boolean>(
    getIfStudentTimesMatchSessionTimes(),
  );

  useEffectSkipMount(() => {
    if (isApplySessionTimesChecked) {
      props.onChange();
      setStudentTimesToSessionTimes();
    }
  }, [isApplySessionTimesChecked]);

  /**
   * #TODO
   * Investigate bug with student arrival and departure times
   * For recurring sessions that are not the first in that series, student arrival and departure times are defaulting to
   * the date of the first session in that series. So even though the hours match, the dates are off which means
   * the apply session times checkbox will not default to being checked when first viewing the session. I don't know if this
   * is caused by the back-end or the front-end at the moment, but since we only need to make sure the hours match, the below variable was updated to
   * a function that only checks hours and not the entire date.
   */
  function getIfStudentTimesMatchSessionTimes(): boolean {
    const startMatches =
      draftSession.studentJournalList![selectedStudentIndex].studentAttendanceRecord!
        .arrivalTime === draftSession.meetingDetails!.startTime!;
    const endMatches =
      draftSession.studentJournalList![selectedStudentIndex].studentAttendanceRecord!
        .departureTime === draftSession.meetingDetails!.endTime!;

    return startMatches && endMatches;
  }

  function setStudentTimesToSessionTimes() {
    setDraftSession(
      produce((draft) => {
        draft.studentJournalList![selectedStudentIndex].studentAttendanceRecord!.arrivalTime =
          draftSession.meetingDetails!.startTime!;
        draft.studentJournalList![selectedStudentIndex].studentAttendanceRecord!.departureTime =
          draftSession.meetingDetails!.endTime!;
      }),
    );
  }

  function toggleApplySessionTimesCheckbox() {
    setIsApplySessionTimesChecked(!isApplySessionTimesChecked);
  }

  return { isApplySessionTimesChecked, toggleApplySessionTimesCheckbox };
}
