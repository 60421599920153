import TabContentWrapper from "../components/tab_content_wrapper";
import CareProvisionsTab from "../tabs/care_provisions_tab";
import GeneraInformationTab from "../tabs/general_information_tab";
import GoalsAndObjectivesTabs from "../tabs/goals_and_objectives_tab";
import MedicaidInfoTab from "../tabs/medicaid_info_tab";
import PlanOfCareInfoTab from "../tabs/plan_of_care_info_tab";

type TabLabel =
  | "General Information"
  | "Plan Of Care Info"
  | "Medicaid Info"
  | "Goals/Obj Info"
  | "Care Provision";

type StudentProfileTab = {
  label: TabLabel;
  content: JSX.Element;
  attributes: {
    shouldRenderTab: (params: { isFeatureDisabledForNonAdmins: boolean }) => boolean;
  };
};

const studentProfileTabsItems: StudentProfileTab[] = [
  {
    label: "General Information",
    content: (
      <TabContentWrapper>
        <GeneraInformationTab />
      </TabContentWrapper>
    ),
    attributes: {
      shouldRenderTab(_) {
        return true;
      },
    },
  },
  {
    label: "Plan Of Care Info",
    content: (
      <TabContentWrapper>
        <PlanOfCareInfoTab />
      </TabContentWrapper>
    ),
    attributes: {
      shouldRenderTab(_) {
        return true;
      },
    },
  },
  {
    label: "Medicaid Info",
    content: (
      <TabContentWrapper>
        <MedicaidInfoTab />
      </TabContentWrapper>
    ),
    attributes: {
      shouldRenderTab({ isFeatureDisabledForNonAdmins }) {
        return !isFeatureDisabledForNonAdmins;
      },
    },
  },
  {
    label: "Goals/Obj Info",
    content: <GoalsAndObjectivesTabs />,
    attributes: {
      shouldRenderTab(_) {
        return true;
      },
    },
  },
  {
    label: "Care Provision",
    content: (
      <TabContentWrapper>
        <CareProvisionsTab />
      </TabContentWrapper>
    ),
    attributes: {
      shouldRenderTab(_) {
        return true;
      },
    },
  },
];

export default studentProfileTabsItems;
