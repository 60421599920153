import { ROUTES_XLOGS } from "../../../constants/URLs";
import InboxIcon from "@mui/icons-material/Inbox";

type SideBarMenuItem = {
  name: string;
  icon: React.ReactNode;
  href: string;
};

const studentsTabSideBarMenuItems: SideBarMenuItem[] = [
  {
    name: "Student Manager",
    href: ROUTES_XLOGS._students.manager,
    icon: <InboxIcon />,
  },
];

export default studentsTabSideBarMenuItems;
