import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { DualActionModal } from "../../../../../design";
import { Typography, SvgIcon } from "@mui/material";

type Props = {
  open: boolean;
  message?: string;
  onClose: () => void;
  onConfirm: () => void;
  onReject: () => void;
};

export default function StudentProfileUnsavedChangesModal(props: Readonly<Props>) {
  return (
    <DualActionModal
      open={props.open}
      onClose={props.onClose}
      onConfirm={props.onConfirm}
      onReject={props.onReject}
      injectContent={{
        header: "Attention",
        body: (
          <Typography variant="body1">
            {props.message ??
              "You have not saved your changes. Would you like to save your progress before you leave page?"}
          </Typography>
        ),
        noText: "No",
        yesText: "Yes",
        icon: (
          <SvgIcon fontSize="large">
            <WarningAmberIcon color="error" />
          </SvgIcon>
        ),
        buttonStyles: {
          yesButton: {
            width: "102px",
            padding: "8px",
            borderRadius: "3px",
          },
          noButton: {
            width: "102px",
            padding: "8px",
            borderRadius: "3px",
            ":hover": {
              bgcolor: "error.main",
              color: "white",
            },
            bgcolor: "error.main",
            color: "white",
          },
        },
      }}
    />
  );
}
