import React, { createContext, useMemo } from "react";
import { useXNGSelector } from "../../../../../context/store";
import {
  selectLoggedInClientAssignment,
  selectUser,
} from "../../../../../context/slices/userProfileSlice";
import { selectStateInUS } from "../../../../../context/slices/stateInUsSlice";
import { ClientAssignment, UserResponse } from "../../../../../profile-sdk";

type caseloadManagementContextType = {
  clientAssignment: ClientAssignment;
  user: UserResponse | null;
  stateInUs: string;
};

export default function CaseloadManagementProvider(props: Readonly<{ children: React.ReactNode }>) {
  const clientAssignment = useXNGSelector(selectLoggedInClientAssignment);
  const user = useXNGSelector(selectUser);
  const stateInUs = useXNGSelector(selectStateInUS);

  const state = useMemo(() => {
    return {
      clientAssignment,
      user,
      stateInUs,
    };
  }, [clientAssignment, user, stateInUs]);

  const CaseloadManagementContext = createContext<caseloadManagementContextType>(
    {} as caseloadManagementContextType,
  );

  return (
    <CaseloadManagementContext.Provider value={state}>
      {props.children}
    </CaseloadManagementContext.Provider>
  );
}
