
import { API_STATESNAPSHOTS } from '../../../../../../api/api';
import useApiQueryData from '../../../../../../api/hooks/use_api_query_data';
import XNGApiQueryParamObject from '../../../../../../types/xng_api_query_param_object';

type Data = Awaited<ReturnType<typeof API_STATESNAPSHOTS.v1StateSnapshotsGoalAreasOfFocusGet>>;
type QueryParams = {
    stateInUs: string, 
};

export default function useQueryGoalAreaOfFocusOptions(paramObject: XNGApiQueryParamObject<QueryParams, Data>) {
    return useApiQueryData({
        queryKey: ["v1StateSnapshotsGoalAreasOfFocusGet", paramObject.queryParams.stateInUs],
        queryFn: async () => {
          return await API_STATESNAPSHOTS.v1StateSnapshotsGoalAreasOfFocusGet(paramObject.queryParams.stateInUs);
        },
        staleTime: 1000 * 60 * 60 * 24, // 24 hours
        ...(paramObject.options ?? {}),
      });
}
