import { Alert, Box, Dialog, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export type HistoricalRecordModalProps = {
  name: string;
  dateRange: string;
  onDelete?: () => void;
};

type Props = {
  title: string;
  subTitle?: string;
  isOpen: boolean;
  canDelete?: boolean;
  onClose?: (event: {}, reason: "backdropClick" | "escapeKeyDown") => void;
  noRecordsText?: string;
  record?: HistoricalRecordModalProps[];
};

const HistoricalRecordsDialog = (props: Props) => {
  return (
    <Dialog
      open={props.isOpen}
      onClose={props.onClose}
      sx={{
        zIndex: 600,
      }}
      PaperProps={{
        sx: {
          maxWidth: "458px",
          minWidth: "458px",
          width: "100%",
          p: 3,
        },
      }}
    >
      <IconButton
        sx={{
          position: "absolute",
          right: "16px",
          top: "16px",
        }}
        onClick={(e) => {
          if (props.onClose) props?.onClose(e, "backdropClick");
        }}
      >
        <CloseIcon />
      </IconButton>

      <Box pb={"2rem"} px={"1rem"}>
        <Typography variant="h4" fontSize={"24px"} mb={"1rem"}>
          {props.title}
        </Typography>
        <Typography variant="h4" fontSize={"14px"} mb={"1rem"}>
          {props.subTitle}
        </Typography>
        <Box
          maxHeight={"300px"}
          minHeight={"200px"}
          sx={{
            overflowY: "auto",
          }}
        >
          {props.record?.map((record, i) => (
            <ModalCard key={i + i} record={record} canDelete={!!props.canDelete} />
          ))}
          {(props.record?.length === 0 || !props.record) && (
            <Alert severity="info">{props.noRecordsText ?? "No Records Found"}</Alert>
          )}
        </Box>
      </Box>
    </Dialog>
  );
};

const ModalCard = ({
  record,
  canDelete,
}: {
  record: HistoricalRecordModalProps;
  canDelete: boolean;
}) => {
  return (
    <Box
      display={"flex"}
      py={"14px"}
      sx={{
        gap: "15px",
        pr: "5px",
        alignItems: "center",
        borderBottom: "1px solid #ddd",
        cursor: "pointer",
        ":hover": {
          backgroundColor: "contrasts.1",
        },
      }}
    >
      <Box flexGrow={1}>{record.name}</Box>
      <Box>{record.dateRange}</Box>
      {canDelete && (
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            record.onDelete?.();
          }}
        >
          <CloseIcon />
        </IconButton>
      )}
    </Box>
  );
};

export default HistoricalRecordsDialog;
