import {
  Box,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import studentsTabSideBarMenuItems from "../constants/students_tab_side_bar_menu_items";
import MenuIcon from "@mui/icons-material/Menu";
import { useLocation } from "react-router-dom";

export default function StudentsTabSideBar({ children }: Readonly<{ children: React.ReactNode }>) {
  const [open, setOpen] = React.useState(false);
  const [expanded, setExpanded] = React.useState(false);
  const location = useLocation();
  const theme = useTheme();
  const tabletMediaQuery = useTheme().breakpoints.down("md");
  const isTabletWidthOrSmaller = useMediaQuery(tabletMediaQuery);

  return (
    <Box sx={{ display: "flex", height: "100%", flexDirection: "column" }}>
      <Box
        sx={{
          display: isTabletWidthOrSmaller ? "flex" : "none",
          flexDirection: "row",
          alignItems: "center",
          px: "20px",
          minHeight: "50px",
          bgcolor: "primary.1",
        }}
      >
        <IconButton onClick={() => setOpen(true)}>
          <MenuIcon fontSize="medium" />
        </IconButton>
      </Box>
      <Box
        sx={{ display: "flex", height: "100%", maxHeight: "100%", position: "relative", flex: 1, overflowY: "hidden" }}
      >
        <Drawer
          open={open}
          onClose={() => setOpen(false)}
          variant={isTabletWidthOrSmaller ? "temporary" : "permanent"}
          PaperProps={{
            sx: {
              position: "relative",
              transition: theme.transitions.create("width", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
              }),
              width: expanded || isTabletWidthOrSmaller ? 200 : 56,

              backgroundColor: "primary.2",
            },
          }}
        >
          <List>
            <ListItem
              disablePadding
              sx={{
                "&:hover": { backgroundColor: "transparent" },
                "&:hover .MuiListItemIcon-root": {
                  backgroundColor: "primary.1",
                  borderRadius: "5px",
                  // display: isTabletWidthOrSmaller ? "none" : "block",
                },
              }}
            >
              <ListItemButton
                onClick={() =>
                  isTabletWidthOrSmaller ? setOpen((prev) => !prev) : setExpanded((prev) => !prev)
                }
              >
                <ListItemIcon sx={{ minWidth: "fit-content", mr: "10px" }}>
                  <MenuIcon fontSize="medium" />
                </ListItemIcon>
              </ListItemButton>
            </ListItem>
            {studentsTabSideBarMenuItems.map((item) => {
              function getBgColor(href: string) {
                return isActive(href) ? "primary.1" : "unset";
              }

              function isActive(href: string) {
                return location.pathname.includes(href);
              }
              return (
                <ListItem key={item.href} disablePadding>
                  <ListItemButton
                    sx={{
                      backgroundColor: getBgColor(item.href),
                      "&:hover": { backgroundColor: "primary.1" },
                    }}
                  >
                    <ListItemIcon sx={{ minWidth: "fit-content", mr: "10px" }}>
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText
                      primary={item.name}
                      sx={{
                        fontWeight: 700,
                        whiteSpace: "nowrap",
                        display: expanded || isTabletWidthOrSmaller ? "block" : "none",
                      }}
                    />
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
        </Drawer>
        {children}
      </Box>
    </Box>
  );
}
