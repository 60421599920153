import { Box } from "@mui/material";
import useStudentProfileContext from "./hooks/use_student_profile_context";
import StudentProfilePageHeader from "./components/student_profile_page_header";
import StudentProfileTab from "./components/student_profile_tab";

export default function StudentProfilePage() {
  const {
    queryStudentProfileApiClient: { data: studentProfileData },
    mutateStudentProfileApiClient: { isPending: savingStudentProfile },
    studentProfileForm: {
      handleSubmit,
      watch,
      formState: { isDirty },
    },
    handleFormSubmit,
    navigation: { handleBackBtnClick, selectedTabIndex, handleSetSelectedTabIndex },
  } = useStudentProfileContext();

  const goalFormId = watch("goalsAndObjectives.goalForm")?.internalId;

  return (
    <Box
      sx={{
        px: "20px",
        position: "relative",
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        pb: 2,
        gap: 5,
      }}
      component={"form"}
      onSubmit={handleSubmit(handleFormSubmit)}
    >
      <StudentProfilePageHeader
        studentProfileData={{
          firstName: studentProfileData?.firstName ?? "",
          middleName: studentProfileData?.middleName ?? "",
          lastName: studentProfileData?.lastName ?? "",
          status: studentProfileData?.xLogsStatus ?? 5,
        }}
        savingStudentProfile={savingStudentProfile}
        disableSaveBtn={!isDirty || goalFormId !== undefined}
        onBackBtnClick={() => {
          handleBackBtnClick();
        }}
      />
      <StudentProfileTab
        tabIndex={selectedTabIndex}
        setTabIndex={handleSetSelectedTabIndex}
        disabled={savingStudentProfile}
      />
    </Box>
  );
}
