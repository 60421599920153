import produce from "immer";
import { API_STUDENTS } from "../../../../../api/api";
import { StudentProfileValidationSchemaT } from "../constants/student_profile_validation_schema";
import { useForm } from "react-hook-form";
import dayjs from "dayjs";
import isInState from "../utils/is_in_state";

/**
 * Custom hook to get default form values from the student profile response.
 *
 * @returns {Object} An object containing the `getDefaultFormValuesFromData` function.
 *
 * @function getDefaultFormValuesFromData
 * @param {Object} params - The parameters for the function.
 * @param {Awaited<ReturnType<typeof API_STUDENTS.v1StudentsIdGet>> | undefined} params.studentProfileApiData - The student profile data retrieved from the API.
 * @param {string} params.stateInUs - The state in the US.
 * @returns {Object} The default form values derived from the student profile API data.
 */
export default function useGetDefaultFormValuesFromStudentProfileResponse() {
  function getDefaultFormValuesFromData(params: {
    studentProfileApiData: Awaited<ReturnType<typeof API_STUDENTS.v1StudentsIdGet>> | undefined;
    stateInUs: string;
  }) {
    const { studentProfileApiData, stateInUs } = params;
    const formDefaultValues: ReturnType<
      typeof useForm<StudentProfileValidationSchemaT>
    >["formState"]["defaultValues"] = {
      generalInformation: {
        firstName: studentProfileApiData?.firstName ?? "",
        middleName: studentProfileApiData?.middleName ?? "",
        lastName: studentProfileApiData?.lastName ?? "",
        dateOfBirth: dayjs(studentProfileApiData?.dateOfBirth).toDate(),
        gender: studentProfileApiData?.gender ?? 0,
        grade: studentProfileApiData?.grade ?? 0,
        primaryLanguage: studentProfileApiData?.primaryLanguage ?? "",
        medicaidId: studentProfileApiData?.medicaidId ?? "",
        studentIdGivenBySchoolDistrict: studentProfileApiData?.studentIdGivenBySchoolDistrict ?? "",
        studentIdGivenByState: studentProfileApiData?.studentIdGivenByState ?? "",
        schoolCampus: produce(studentProfileApiData?.schoolCampuses?.[0] ?? {}, (draft) => draft),
        districtOfLiabilityRecord: produce(
          studentProfileApiData?.districtOfLiabilityRecords?.[0] ?? {},
          (draft) => draft,
        ),
      },
      planOfCareInfo: {
        plansOfCare: produce(
          studentProfileApiData?.spedDossier?.plansOfCare ?? [],
          (draft) => draft,
        ),
        primaryDisability:
          produce(studentProfileApiData?.spedDossier?.primaryDisability, (draft) => draft) ?? null,
        secondaryDisability:
          produce(studentProfileApiData?.spedDossier?.secondaryDisability, (draft) => draft) ??
          null,
        tertiaryDisability:
          produce(studentProfileApiData?.spedDossier?.tertiaryDisability, (draft) => draft) ?? null,
      },
      medicaidInfo: {
        // studentProfileApiData?.spedDossier?.eligibilityRecords?.[0] ?? null
        eligibilityRecord:
          produce(
            studentProfileApiData?.spedDossier?.eligibilityRecords?.[0] ?? null,
            (draft) => draft,
          ) ?? null,
        consentRecord:
          produce(studentProfileApiData?.spedDossier?.consent?.[0], (draft) => draft) ?? null,
        prescribedServiceAreas:
          produce(studentProfileApiData?.spedDossier?.prescribedServiceAreas, (draft) => draft) ??
          [],
        personalCareSupplement: produce(
          studentProfileApiData?.spedDossier?.personalCareSupplement,
          (draft) => draft,
        ),
        speciallyAdjustedVehicle: produce(
          studentProfileApiData?.spedDossier?.speciallyAdjustedVehicle,
          (draft) => draft,
        ),
        personalCareServicesDecision: produce(
          studentProfileApiData?.spedDossier?.prescribedServiceAreas?.find(
            (psa) => psa.serviceArea?.id === "5",
          )?.decision ?? 2,
          (draft) => draft,
        ),
        specializedTransportationDecision: produce(
          studentProfileApiData?.spedDossier?.prescribedServiceAreas?.find((psa) => {
            if (isInState(stateInUs, "TX")) {
              return psa.serviceArea?.id === "11";
            } else if (isInState(stateInUs, "NH")) {
              return psa.serviceArea?.id === "9";
            }

            return false;
          })?.decision ?? 2,
          (draft) => draft,
        ),
        personalCareServicesOrderedOnTheBusDecision: produce(
          studentProfileApiData?.spedDossier?.prescribedServiceAreas?.find((psa) => {
            if (isInState(stateInUs, "TX")) {
              return psa.serviceArea?.id === "6";
            }
            return false;
          })?.decision ?? 2,
          (draft) => draft,
        ),
        billingBlockTerm: produce(
          studentProfileApiData?.spedDossier?.billingBlockTerm,
          (draft) => draft,
        ),
      },
      goalsAndObjectives: {
        goals:
          produce(
            studentProfileApiData?.spedDossier?.prescribedCareProvisionsLedger?.goals as [],
            (draft) => draft,
          ) ?? [],
        goalForm: null,
      },
      careProvisions: {
        accommodations: produce(
          studentProfileApiData?.spedDossier?.prescribedCareProvisionsLedger?.accommodations ?? [],
          (draft) => draft,
        ),
        activities: produce(
          studentProfileApiData?.spedDossier?.prescribedCareProvisionsLedger?.activities ?? [],
          (draft) => draft,
        ),
        modifications: produce(
          studentProfileApiData?.spedDossier?.prescribedCareProvisionsLedger?.modifications ?? [],
          (draft) => draft,
        ),
      },
    };

    return formDefaultValues;
  }

  return {
    getDefaultFormValuesFromData,
  };
}
