import { UseMutationResult, UseQueryResult } from "@tanstack/react-query";
import React, { useEffect, useMemo } from "react";
import { useXNGSelector } from "../../../../../context/store";
import { selectStateInUS } from "../../../../../context/slices/stateInUsSlice";
import { selectClientID } from "../../../../../context/slices/loggedInClientSlice";
import { Outlet, useNavigate, useParams } from "react-router";
import { API_STUDENTS } from "../../../../../api/api";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import studentProfileValidationSchema, {
  StudentProfileValidationSchemaT,
} from "../constants/student_profile_validation_schema";
import StudentProfileLayout from "../student_profile_layout";
import useApiQueryData from "../../../../../api/hooks/use_api_query_data";
import produce from "immer";
import useStudentProfileBackbtnClickedMiddleware from "../hooks/use_student_profile_backbtn_clicked_middleware";
import useApiMutateData from "../../../../../api/hooks/use_api_mutate_data";
import {
  ConsentForMedicaidBilling,
  Disability,
  EligibilityRecord,
  Grade,
  PatchStudentRequest,
  PlanOfCare,
  PrescribedServiceArea,
  ServiceAreaRef,
  StudentProfile,
} from "../../../../../profile-sdk";
import useGetDefaultFormValuesFromStudentProfileResponse from "../hooks/use_get_default_form_values_from_student_profile_response";
import isInState from "../utils/is_in_state";

export default function StudentProfileContextProvider() {
  const { studentID } = useParams<{ studentID: string }>();
  const stateInUs = useXNGSelector(selectStateInUS);
  const loggedInClientId = useXNGSelector(selectClientID);
  const navigate = useNavigate();

  const [selectedTabIndex, setSelectedTabIndex] = React.useState(0);

  const queryStudentProfileApiClient = useApiQueryData({
    queryKey: ["v1StudentsIdGet", stateInUs, loggedInClientId, studentID],
    queryFn: async () => {
      return await API_STUDENTS.v1StudentsIdGet(studentID!, loggedInClientId, stateInUs);
    },
  });

  const mutateStudentProfileApiClient = useApiMutateData({
    mutationKey: ["v1StudentsIdPatch", stateInUs, studentID],
    mutationFn: async (payload: StudenProfileApiMutatePayload) => {
      const { id, state, body } = payload;
      return await API_STUDENTS.v1StudentsIdPatch(id, state, body);
    },
  });

  const { getDefaultFormValuesFromData } = useGetDefaultFormValuesFromStudentProfileResponse();

  const studentProfileForm = useForm<StudentProfileValidationSchemaT>({
    resolver: yupResolver(studentProfileValidationSchema),
    defaultValues: getDefaultFormValuesFromData({
      studentProfileApiData: produce(queryStudentProfileApiClient.data, (draft) => draft),
      stateInUs,
    }),
  });

  const { backBtnClicked, handleBackBtnClick } =
    useStudentProfileBackbtnClickedMiddleware({
      formIsDirty: studentProfileForm.formState.isDirty,
      onBackBtnClicked: () => {
        navigate(-1);
      },
    });

  function resetForm(data: StudentProfile) {
    studentProfileForm.reset(
      getDefaultFormValuesFromData({
        studentProfileApiData: produce(data, (draft) => draft),
        stateInUs,
      }),
      {
        keepTouched: true,
      },
    );
  }

  function handleFormSubmit(data: StudentProfileValidationSchemaT) {
    // this is where we do any data transformation on our form data before mapping our API payload to match the API requirements
    const remapedFormData = produce(data ?? {}, (draft) => {
      /* 
      if the last items in the care provisions objects are empty then we filter them out, 
      this cleanup is necessary due to the fact that we always add an empty object to the end of the array to allow users to add more items to the array
      */
      if (
        draft.careProvisions.accommodations.length > 0 &&
        !draft.careProvisions.accommodations.at(-1)?.name?.trim()
      ) {
        draft.careProvisions.accommodations.pop();
      }
      if (
        draft.careProvisions.activities.length > 0 &&
        !draft.careProvisions.activities.at(-1)?.name?.trim()
      ) {
        draft.careProvisions.activities.pop();
      }
      if (
        draft.careProvisions.modifications.length > 0 &&
        !draft.careProvisions.modifications.at(-1)?.name?.trim()
      ) {
        draft.careProvisions.modifications.pop();
      }
    });

    const {
      generalInformation: { grade, schoolCampus, districtOfLiabilityRecord, ...generalInfo },
      planOfCareInfo: { plansOfCare, ...otherPlanOfCareInfo },
      medicaidInfo,
      careProvisions,
      goalsAndObjectives,
    } = remapedFormData;

    const payload: StudenProfileApiMutatePayload = {
      id: studentID!,
      state: stateInUs,
      body: {
        ...generalInfo,
        districtOfLiabilityRecord: {
          ...districtOfLiabilityRecord,
          startDate: districtOfLiabilityRecord.startDate ?? undefined,
          endDate: districtOfLiabilityRecord.endDate ?? undefined,
        },
        client: queryStudentProfileApiClient.data?.client,
        grade: grade as Grade,
        schoolCampus: {
          ...schoolCampus,
          attendanceEndDate: schoolCampus.attendanceEndDate ?? undefined,
        },
        spedDossier: {
          plansOfCare: plansOfCare as PlanOfCare[],
          primaryDisability: otherPlanOfCareInfo.primaryDisability as Disability,
          secondaryDisability: otherPlanOfCareInfo.secondaryDisability as Disability,
          tertiaryDisability: otherPlanOfCareInfo.tertiaryDisability as Disability,
          eligibilityRecords: produce(
            queryStudentProfileApiClient.data?.spedDossier?.eligibilityRecords ?? [],
            (draft) => {
              const eligibilityRecord = medicaidInfo.eligibilityRecord;
              if (eligibilityRecord) {
                draft.unshift(eligibilityRecord as EligibilityRecord);
              }
            },
          ),
          consent: produce(
            queryStudentProfileApiClient.data?.spedDossier?.consent ?? [],
            (draft) => {
              const consentRecord = medicaidInfo.consentRecord;
              if (consentRecord) {
                draft.unshift(consentRecord as ConsentForMedicaidBilling);
              }
            },
          ),
          prescribedServiceAreas: produce(
            (medicaidInfo.prescribedServiceAreas as PrescribedServiceArea[]) ?? [],
            (draft) => {
              /* 
                 here we create the prescribed service areas for the medicaidInfo properties that only have a single value (decision) in the UI,
                 the others prescriptions, have input fields for state date, end date and prescribed provider, so they're already included 
                 and properly populated in the mutable draft object
              */

              if (
                isInState(stateInUs, "TX") &&
                (medicaidInfo.personalCareServicesDecision !== undefined ||
                  medicaidInfo.personalCareServicesDecision !== null)
              ) {
                const personalCareServicesIndex = draft.findIndex((d) => d.serviceArea?.id === "5");
                if (personalCareServicesIndex !== -1) {
                  draft[personalCareServicesIndex].decision =
                    medicaidInfo.personalCareServicesDecision ?? 2;
                } else {
                  draft.push({
                    id: crypto.randomUUID(),
                    decision: medicaidInfo.personalCareServicesDecision ?? 2,
                    serviceArea: { id: "5", name: "Personal Care Services" },
                  });
                }
              }

              if (
                medicaidInfo.specializedTransportationDecision !== undefined ||
                medicaidInfo.specializedTransportationDecision !== null
              ) {
                const specializedTransportationIndex = draft.findIndex(
                  (d) => d.serviceArea?.id === (isInState(stateInUs, "NH") ? "9" : "11"),
                );

                if (specializedTransportationIndex !== -1) {
                  draft[specializedTransportationIndex].decision =
                    medicaidInfo.specializedTransportationDecision ?? 2;
                } else {
                  //create service area if it does not exist
                  const serviceArea: ServiceAreaRef = {
                    id: isInState(stateInUs, "NH") ? "9" : "11",
                    name: "Specialized Transportation",
                  };
                  const prescribedServiceArea: PrescribedServiceArea = {
                    id: crypto.randomUUID(),
                    decision: medicaidInfo.specializedTransportationDecision ?? 2,
                    serviceArea,
                    startDate: new Date(),
                  };
                  draft.push(prescribedServiceArea);
                }
              }

              if (
                isInState(stateInUs, "TX") &&
                (medicaidInfo.personalCareServicesOrderedOnTheBusDecision !== undefined ||
                  medicaidInfo.personalCareServicesOrderedOnTheBusDecision !== null)
              ) {
                const personalCareServicesOrderedOnTheBusDecisionIndex = draft.findIndex(
                  (d) => d.serviceArea?.id === "6",
                );

                if (personalCareServicesOrderedOnTheBusDecisionIndex !== -1) {
                  draft[personalCareServicesOrderedOnTheBusDecisionIndex].decision =
                    medicaidInfo.personalCareServicesOrderedOnTheBusDecision ?? 2;
                } else {
                  //create service area if it does not exist
                  const serviceArea: ServiceAreaRef = {
                    id: "6",
                    name: "Personal Care on Bus Services",
                  };
                  const prescribedServiceArea: PrescribedServiceArea = {
                    id: crypto.randomUUID(),
                    decision: medicaidInfo.personalCareServicesOrderedOnTheBusDecision ?? 2,
                    serviceArea,
                    startDate: new Date(),
                  };

                  draft.push(prescribedServiceArea);
                }
              }
            },
          ),
          billingBlockTerm: medicaidInfo.billingBlockTerm as any,
          personalCareSupplement: medicaidInfo.personalCareSupplement ?? undefined,
          speciallyAdjustedVehicle: medicaidInfo.speciallyAdjustedVehicle ?? undefined,
          prescribedCareProvisionsLedger: {
            goals: goalsAndObjectives.goals as any,
            accommodations: careProvisions.accommodations as any,
            activities: careProvisions.activities as any,
            modifications: careProvisions.modifications as any,
          },
        },
      },
    };

    mutateStudentProfileApiClient.mutate(payload, {
      onSuccess: (data) => {
        queryStudentProfileApiClient.refetch();
        resetForm(data);
      },
      onError: (_) => {
        const values = studentProfileForm.getValues();
        studentProfileForm.reset(produce(values, draft => draft), {
          keepDirty: true
        })
      },
    });
  }

  const contextValue: StudentProfileContextT = useMemo(
    () => ({
      queryStudentProfileApiClient,
      mutateStudentProfileApiClient,
      studentProfileForm,
      handleFormSubmit,
      navigation: {
        backBtnClicked,
        handleBackBtnClick,
        selectedTabIndex,
        handleSetSelectedTabIndex(index) {
          setSelectedTabIndex(index);
        },
      },
      globalState: {
        stateInUs,
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [backBtnClicked, queryStudentProfileApiClient, stateInUs, studentProfileForm],
  );

  useEffect(() => {
    if (queryStudentProfileApiClient.status === "success") {
      resetForm(queryStudentProfileApiClient.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryStudentProfileApiClient.status]);

  return (
    <StudentProfileContext.Provider value={contextValue}>
      <StudentProfileLayout>
        <Outlet />
      </StudentProfileLayout>
    </StudentProfileContext.Provider>
  );
}
type StudenProfileApiMutatePayload = { id: string; state: string; body: PatchStudentRequest };

export type StudentProfileContextT = {
  queryStudentProfileApiClient: UseQueryResult<
    Awaited<ReturnType<typeof API_STUDENTS.v1StudentsIdGet>>,
    Error
  >;
  mutateStudentProfileApiClient: UseMutationResult<
    Awaited<ReturnType<typeof API_STUDENTS.v1StudentsIdPatch>>,
    Error,
    StudenProfileApiMutatePayload
  >;
  studentProfileForm: ReturnType<typeof useForm<StudentProfileValidationSchemaT>>;
  handleFormSubmit(data: StudentProfileValidationSchemaT): void;
  navigation: {
    backBtnClicked: boolean;
    handleBackBtnClick: (param?: { ignoreIsDirty?: boolean; resetClickState?: boolean }) => void;
    selectedTabIndex: number;
    handleSetSelectedTabIndex: (index: number) => void;
  };
  globalState: {
    stateInUs: string;
  };
};

export const StudentProfileContext = React.createContext({} as StudentProfileContextT);
