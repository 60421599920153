import { XNGICONS, XNGIconRenderer } from "../../icons";
import { getSizing } from "../../sizing";
import Box from "../../components-dev/BoxExtended";
import {
  SidebarItemAnyProps as SidebarItemAnyProps,
  ButtonSidebarItemProps as ButtonSidebarItemProps,
  DropdownSidebarItemProps as DropdownSidebarItemProps,
} from "../../types/sidebar_content";
import { useXNGDispatch, useXNGSelector } from "../../../context/store";
import { setSidebarOpen, sidebarOpen } from "../../../context/slices/sidebarSlice";
import SquareIconButton from "./icon_button_square";
import { SIZE, useSidebarPalette } from "./_";
import ButtonSidebarItem from "./sidebar_item_button";
import DropdownSidebarItem from "./sidebar_item_dropdown";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { MSBICONS } from "../../../fortitude";
import SidebarAccordion from "../../../views/admin/common/sidebar_accordion";
import useSidebarLayoutTabs from "../../../views/admin/constants/sidebar_layout_btns";
import { ROUTES_XLOGS } from "../../../constants/URLs";
import { selectStateInUS } from "../../../context/slices/stateInUsSlice";

interface IXNGSidebar {
  sidebarContent: (ButtonSidebarItemProps | DropdownSidebarItemProps)[];
  view?: "admin" | "student";
}
function XNGSidebar(props: IXNGSidebar) {
  // HOOKS
  const palette = useSidebarPalette();
  const location = useLocation();
  const dispatch = useXNGDispatch();
  const { toolsButtons, unassignedButtons, userManagementButtons, caseloadManagementButtons } =
    useSidebarLayoutTabs();

  // CONSTANTS
  const EXPANDED_WIDTH = getSizing(36);

  // REDUX STATE
  const isSidebarOpen = useXNGSelector(sidebarOpen);
  const state = useXNGSelector(selectStateInUS);

  // TYPE CHECKERS
  function sbiIsDropdown(sbi: SidebarItemAnyProps) {
    return "tabs" in sbi;
  }

  // STATES
  const [open, setOpen] = useState<number | null>(null);
  const [expandedAccordions, setExpandedAccordions] = useState<String[]>([]);

  // CALLBACKS
  function onSidebarClose() {
    // close all dropdowns, if any
    setOpen(null);
    setExpandedAccordions([]);
  }

  function onSidebarOpen() {
    setExpandedAccordions(["User Management", "Caseload Management", "Tools"]);
    setOpen(1);
  }

  function onAccordionClick(description: string) {
    dispatch(setSidebarOpen(true));
    setOpen(1);
    if (expandedAccordions.includes(description)) {
      setExpandedAccordions(expandedAccordions.filter((item) => item !== description));
    } else {
      setExpandedAccordions([...expandedAccordions, description]);
    }
  }

  // DOM HIERARCHY
  return (
    <Box
      sx={{
        width: isSidebarOpen ? EXPANDED_WIDTH : getSizing(7),
        bgcolor: palette.bgcolor,
        height: "100%",
        padding: getSizing(1),
        overflow: "hidden",
        transition: "width .2s ease",
      }}
    >
      <Box width={EXPANDED_WIDTH}>
        <MainHamburger onSidebarClose={() => onSidebarClose()} onSidebarOpen={onSidebarOpen} />
        {props.view === "admin" ? (
          <>
            {state !== "NH" ? (
              <ButtonSidebarItem
                displayAsSelected={location.pathname === ROUTES_XLOGS._admin.mydistrictProfile}
                item={{
                  icon: <MSBICONS.Home />,
                  label: "My District Profile",
                  route: ROUTES_XLOGS._admin.mydistrictProfile,
                }}
              />
            ) : null}
            <SidebarAccordion
              children={props.sidebarContent}
              onClick={onAccordionClick}
              description="User Management"
              expanded={!!open && expandedAccordions.includes("User Management")}
              icon={<MSBICONS.AvatarWithCircleOutline />}
            />
            <SidebarAccordion
              children={caseloadManagementButtons}
              onClick={onAccordionClick}
              description="Caseload Management"
              expanded={!!open && expandedAccordions.includes("Caseload Management")}
              icon={<MSBICONS.Hierarchy />}
            />
            <SidebarAccordion
              children={toolsButtons}
              onClick={onAccordionClick}
              description="Tools"
              expanded={!!open && expandedAccordions.includes("Tools")}
              icon={<MSBICONS.Tools />}
            />
            {unassignedButtons.map((sbi: SidebarItemAnyProps, i: number) => (
              <ButtonSidebarItem
                displayAsSelected={location.pathname === sbi.route}
                item={sbi as ButtonSidebarItemProps}
                key={i}
              />
            ))}
          </>
        ) : (
          <>
            {props.sidebarContent.map((sbi: SidebarItemAnyProps, i: number) => {
              if (sbiIsDropdown(sbi)) {
                return (
                  <DropdownSidebarItem
                    displayAsSelected={location.pathname === sbi.route}
                    item={sbi as DropdownSidebarItemProps}
                    isOpen={i === open}
                    onSetOpen={() => {
                      if (!isSidebarOpen) {
                        setOpen(i);
                        dispatch(setSidebarOpen(true));
                        return;
                      }
                      if (i === open) {
                        setOpen(null);
                      } else {
                        setOpen(i);
                      }
                    }}
                    key={i}
                  />
                );
              }
              return (
                <ButtonSidebarItem
                  displayAsSelected={location.pathname === sbi.route}
                  item={sbi as ButtonSidebarItemProps}
                  key={i}
                />
              );
            })}
          </>
        )}
      </Box>
    </Box>
  );
}

function MainHamburger(props: { onSidebarClose: () => void; onSidebarOpen: () => void }) {
  const dispatch = useXNGDispatch();
  const isSidebarOpen = useXNGSelector(sidebarOpen);
  const palette = useSidebarPalette();

  return (
    <SquareIconButton
      onClick={() => {
        if (isSidebarOpen) {
          props.onSidebarClose();
          dispatch(setSidebarOpen(false));
        } else {
          props.onSidebarOpen();
          dispatch(setSidebarOpen(true));
        }
      }}
      sx={{ marginBottom: getSizing(3) }}
    >
      <XNGIconRenderer color={palette.deselected} size={SIZE} i={<XNGICONS.Hamburger />} />
    </SquareIconButton>
  );
}

export default XNGSidebar;
