import { Box, Tab, Tabs } from "@mui/material";
import React, { useMemo } from "react";
import studentProfileTabsItems from "../constants/student_profile_tabs_items";
import { useXNGSelector } from "../../../../../context/store";

type Props = {
  tabIndex: number;
  setTabIndex: (index: number) => void;
  disabled?: boolean;
};

export default function StudentProfileTab(props: Readonly<Props>) {
  const { tabIndex, setTabIndex, disabled } = props;

  const isFeatureDisabledForNonAdmins = useXNGSelector(state => state.featureFlags.flags.DisableMedicaidTabForNonAdmins)

  function handleChange(_: React.SyntheticEvent, newValue: number) {
    setTabIndex(newValue);
  }

  const visibleStudentProfileTabs = useMemo(() => {
    return studentProfileTabsItems.filter((tab) =>
      tab.attributes.shouldRenderTab({
        isFeatureDisabledForNonAdmins,
      }),
    );
  }, [isFeatureDisabledForNonAdmins]);

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        flex: 1,
        overflow: "hidden",
      }}
    >
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={tabIndex}
          onChange={handleChange}
          aria-label="student profile tabs"
          scrollButtons="auto"
          variant="scrollable"
          allowScrollButtonsMobile
        >
          {visibleStudentProfileTabs
            .filter((tab) =>
              tab.attributes.shouldRenderTab({
                isFeatureDisabledForNonAdmins,
              }),
            )
            .map((tab, i) => {
              return (
                <Tab
                  key={tab.label}
                  label={tab.label}
                  sx={{ textTransform: "capitalize", paddingLeft: 0, fontWeight: tabIndex === i ? "bold" : "normal" }}
                  disabled={disabled}
                />
              );
            })}
        </Tabs>
      </Box>
      <Box
        sx={{
          overflowY: "auto",
          height: "100%",
          display: visibleStudentProfileTabs[tabIndex].attributes.shouldRenderTab({
            isFeatureDisabledForNonAdmins,
          })
            ? "block"
            : "none",
        }}
      >
        {visibleStudentProfileTabs[tabIndex].content}
      </Box>
    </Box>
  );
}
