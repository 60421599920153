import { useXNGSelector } from "../../../context/store";
import { ROUTES_XLOGS } from "../../../constants/URLs";
import { ButtonSidebarItemProps } from "../../../design/types/sidebar_content";
import { MSBICONS } from "../../../fortitude";
import { Accordion, AccordionSummary, AccordionDetails, Typography } from "@mui/material";
import ButtonSidebarItem from "../../../design/high-level/sidebar/sidebar_item_button";
import { XNGIconRenderer, XNGICONS } from "../../../design";

function useSidebarLayoutTabs() {
  const studentMergeActive = useXNGSelector(
    (state) => state.featureFlags.flags["StudentMergeActive"],
  );

  const userManagementButtons: ButtonSidebarItemProps[] = [
    {
      icon: <MSBICONS.AvatarWithCircleOutline />,
      label: "User Approvals",
      route: ROUTES_XLOGS._admin.userApproval,
    },
    {
      icon: <MSBICONS.TwoPeople />,
      label: "Staff Directory Manager",
      route: ROUTES_XLOGS._admin.staffDirectoryManager,
    },
    {
      icon: <MSBICONS.PrescribingProviderIcon />,
      label: "Prescribing Provider ",
      route: ROUTES_XLOGS._admin.prescribingProvider,
    },
    {
      icon: <MSBICONS.Home />,
      label: "Campus Management",
      route: ROUTES_XLOGS._admin.campusManagement,
    },
  ];

  const caseloadManagementButtons: ButtonSidebarItemProps[] = [
    {
      icon: <MSBICONS.Hierarchy />,
      label: "Approver Caseloads",
      route: ROUTES_XLOGS._admin.approverCaseloads,
    },
  ];

  const toolsButtons: ButtonSidebarItemProps[] = [];

  const unassignedButtons: ButtonSidebarItemProps[] = [];

  if (studentMergeActive) {
    toolsButtons.push({
      icon: <MSBICONS.Merge />,
      label: "Student Merge",
      route: ROUTES_XLOGS._admin.studentMerge,
    });
  }
  return { userManagementButtons, caseloadManagementButtons, toolsButtons, unassignedButtons };
}

export default useSidebarLayoutTabs;
