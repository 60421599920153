import { Button } from "@mui/material";
import usePalette from "../../hooks/usePalette";

interface IXNGHyperlink {
  children: React.ReactNode;
  onClick: () => void;
  width?: string;
  color?: string;
  disabled?: boolean;
}

/**
 * ## DEPRECATED
 *
 * Do not reuse or modify, replace references with MUI / Fortitude components in new development.
 *
 * See: https://msb-tlm.atlassian.net/wiki/spaces/XL/pages/508035073/MSB+Design+System+Fortitude#Note-for-Developers%3A-Deprecated-Design-System-Exists-in-XNG-Codebase
 */
function XNGHyperlink(props: IXNGHyperlink) {
  const palette = usePalette();

  return (
    <Button
      variant="text"
      sx={{
        width: props.width || "min-content",
        textDecoration: "underline",
        color: props?.disabled ? palette.disabled: props.color || palette.primary[2],
        cursor: "pointer",
      }}
      onClick={() => props.onClick()}
      className="noselect"
      disabled={props?.disabled}
    >
      {props.children}
    </Button>
  );
}

export default XNGHyperlink;
