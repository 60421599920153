import { Box } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import GoalsAndObjectivesListView from "../components/goals_and_obj/goals_and_objectives_list_view";
import CreateOrEditGoalForm from "../components/goals_and_obj/create_or_edit_goal_form";
import { Goal } from "@xng/profile";
import useStudentProfileContext from "../hooks/use_student_profile_context";
import { StudentProfileValidationSchemaT } from "../constants/student_profile_validation_schema";
import useGoalAreaOfFocusOptionsAndFilterSelectionHandler from "../hooks/goals_and_objectives_tab/use_goal_area_of_focus_options_and_filter_selection_handler";

/* 
    the context definition of the goals and objectives tab
*/
/**
 * Represents the state properties for the Goals and Objectives tab.
 *
 * @property {"listView" | "createOrEditView"} type - The type of view currently active in the Goals and Objectives tab.
 * @property {Goal | null} goal - The goal object being submitted by our goal form.
 */
export type GoalsAndObjectivesStateProps = {
  type: "listView" | "createOrEditView";
  goal: Goal | null;
};

export default function GoalsAndObjectivesTabs() {
  const { studentProfileForm, globalState:{stateInUs} } = useStudentProfileContext();

  const [selectedView, setSelectedView] = useState<{
    type: "listView" | "createOrEditView";
  }>({
    type: "listView",
  });

  const goalAreaOfFocusSelectionAndOptions = useGoalAreaOfFocusOptionsAndFilterSelectionHandler({
    stateInUs
  })

  /**
   * Handles the change of view for goals and objectives.
   *
   * @param {GoalsAndObjectivesStateProps} newStateProps - The new state properties for goals and objectives.
   * @returns {void}
   *
   * @remarks
   * - Updates the form value for `goalsAndObjectives.goalForm` based on the provided `newStateProps`.
   * - Resets the `goalForm` field to `null` if `newStateProps.goal` is not provided.
   * - Generates a new UUID for `internalId` if the view type is `createOrEditView`.
   * - Updates the selected view type based on `newStateProps.type`.
   */
  const handleChangeView = (newStateProps: GoalsAndObjectivesStateProps): void => {
    studentProfileForm?.setValue?.(
      "goalsAndObjectives.goalForm",
      (newStateProps?.goal ?? null) as
        | StudentProfileValidationSchemaT["goalsAndObjectives"]["goalForm"]
        | null,
    );
    if (!newStateProps.goal) {
      studentProfileForm?.resetField("goalsAndObjectives.goalForm", {
        defaultValue: null,
      });
      /* 
      This is important as we use this value to render our list of goals in the list view 
      and is also coupled to the profile save button, as this is the value that determines if we're in the editOrCreate mode or not.
      If you observe closely, the "Save" button is disabled whenever we are in EditOrCreate mode, and this is because the goalForm internal Id has been set.
      */
      if (newStateProps.type === "createOrEditView")
        studentProfileForm?.setValue?.(
          "goalsAndObjectives.goalForm.internalId",
          crypto.randomUUID(),
        );
    }
    setSelectedView({
      type: newStateProps.type,
    });
  };

  const renderSelectedView = useMemo(() => {
    switch (selectedView.type) {
      case "listView": {
        return <GoalsAndObjectivesListView goalAreaOfFocusSelectionAndOptions={goalAreaOfFocusSelectionAndOptions} handleChangeView={handleChangeView} />;
      }
      case "createOrEditView": {
        return <CreateOrEditGoalForm goalAreaOfFocusApiClient={goalAreaOfFocusSelectionAndOptions.goalAreaOfFocusOptionsApiClient} handleChangeView={handleChangeView} />;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedView, goalAreaOfFocusSelectionAndOptions]);

  useEffect(() => {
    //reset goal form if we are in list view and goal form is not null, this can happen when user clicks on another tab and comes back to this tab
    const goalForm = studentProfileForm?.getValues("goalsAndObjectives.goalForm");
    if (goalForm && selectedView.type === "listView") {
      studentProfileForm?.resetField("goalsAndObjectives.goalForm", {
        defaultValue: null,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      sx={{
        maxWidth: "lg",
      }}
    >
      {renderSelectedView}
    </Box>
  );
}
