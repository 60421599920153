import { Box, Button, Divider, MenuItem, Typography } from "@mui/material";
import { GoalsAndObjectivesStateProps } from "../../tabs/goals_and_objectives_tab";
import GoalsAndObjTabNavbarWrapper from "./goals_and_obj_tab_navbar_wrapper";
import StudentProfileBackBtn from "../student_profile_back_btn";
import { Controller } from "react-hook-form";
import InputFieldGrid from "../input_field_grid";
import useStudentProfileContext from "../../hooks/use_student_profile_context";
import MSBInputErrorWrapper from "../../../../../../fortitude/components/input_error_wrapper";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import produce from "immer";
import useStudentProfileBackbtnClickedMiddleware from "../../hooks/use_student_profile_backbtn_clicked_middleware";
import StudentProfileUnsavedChangesModal from "../student_profile_unsaved_changes_modal";
import StudentProfileTextField from "../student_profile_text_field";
import { useState } from "react";
import QueryStatusModal from "../../../../../../design/modal_templates/query_status_modal";
import useQueryGoalAreaOfFocusOptions from "../../hooks/api/use_query_goal_area_of_focus_options";

type Props = {
  goalAreaOfFocusApiClient: ReturnType<typeof useQueryGoalAreaOfFocusOptions>;
  handleChangeView: (view: GoalsAndObjectivesStateProps) => void;
};

export default function CreateOrEditGoalForm(props: Readonly<Props>) {
  const { handleChangeView, goalAreaOfFocusApiClient } = props;
  const {
    studentProfileForm: {
      control,
      register,
      getValues,
      resetField,
      watch,
      setValue,
      trigger,
      formState: { errors, dirtyFields },
    },
  } = useStudentProfileContext();

  const [showValidationErrorModal, setShowValidationErrorModal] = useState(false);

  const goalObjectives = watch("goalsAndObjectives.goalForm.objectives");

  const { backBtnClicked, handleBackBtnClick } =
    useStudentProfileBackbtnClickedMiddleware({
      formIsDirty: !!dirtyFields.goalsAndObjectives?.goalForm,
      onBackBtnClicked() {
        handleChangeView({
          type: "listView",
          goal: null,
        });
      },
    });

  async function onSubmitGoalsAndObj() {
    const isValid = await trigger("goalsAndObjectives.goalForm");

    if (isValid) {
      const goalForm = getValues("goalsAndObjectives.goalForm");
      const goalsArrayFormValue = getValues("goalsAndObjectives.goals");
      const updatedGoalsArray = produce(goalsArrayFormValue ?? [], (draft) => {
        /* perform transformation on goalForm to remove empty added objective object to end of objectives array
             this is necessary to ensure we aren't adding empty objectives and messing up data as we don't have a way to delete objectives 
             and the implementation creates an edge case where a provider can add a pointless objective object to their data
          */
        if (
          goalForm?.objectives &&
          goalForm?.objectives?.length > 0 &&
          !goalForm?.objectives?.at(-1)?.description?.trim() &&
          !goalForm?.objectives?.at(-1)?.number?.trim()
        ) {
          goalForm.objectives.pop();
        }

        const goalIndex = draft.findIndex((goal) => goal.internalId === goalForm?.internalId);

        if (goalIndex !== -1) {
          draft[goalIndex] = {
            ...draft[goalIndex],
            ...goalForm,
          };
        } else if (goalForm) {
          draft.push(goalForm);
        }
      });

      setValue("goalsAndObjectives.goals", updatedGoalsArray, {
        shouldDirty: true,
      });
      resetField("goalsAndObjectives.goalForm", {
        defaultValue: null,
      });
      setValue("goalsAndObjectives.goalForm", goalForm);
    }

    return isValid;
  }

  return (
    <>
      <Box>
        <GoalsAndObjTabNavbarWrapper
          sx={{
            justifyContent: "space-between",
            mb: 4,
            zIndex: 30,
          }}
        >
          <StudentProfileBackBtn
            onClick={() => {
              handleBackBtnClick();
            }}
          />
          <Button
            disabled={!dirtyFields.goalsAndObjectives?.goalForm}
            sx={{ borderRadius: 0 }}
            onClick={onSubmitGoalsAndObj}
          >
            Save Goal/Objectives
          </Button>
        </GoalsAndObjTabNavbarWrapper>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 4 }}>
          <Box>
            <Typography variant="h6" mb={2} fontWeight={700}>
              Goal Information
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
              <InputFieldGrid
                items={[
                  {
                    key: "goalNumber",
                    content: (
                      <StudentProfileTextField
                        id="goalNumber"
                        label="Goal Number"
                        {...register("goalsAndObjectives.goalForm.number")}
                        helperText={errors.goalsAndObjectives?.goalForm?.number?.message}
                        FormHelperTextProps={{
                          error: !!errors.goalsAndObjectives?.goalForm?.number?.message,
                        }}
                      />
                    ),
                  },
                  {
                    key: "goalStatus",
                    content: (
                      <Controller
                        control={control}
                        name="goalsAndObjectives.goalForm.status"
                        render={({ field, fieldState: { error } }) => {
                          return (
                            <StudentProfileTextField
                              select
                              id="status"
                              label={"Goal Status"}
                              value={field.value ?? ""}
                              onChange={field.onChange}
                              helperText={error?.message}
                              FormHelperTextProps={{
                                error: !!error?.message,
                              }}
                            >
                              {["Active", "Inactive"].map((status) => (
                                <MenuItem key={status} value={status}>
                                  {status}
                                </MenuItem>
                              ))}
                            </StudentProfileTextField>
                          );
                        }}
                      />
                    ),
                  },
                  {
                    key: "GoalAreaOfFocus",
                    content: (
                      <Controller
                        control={control}
                        name="goalsAndObjectives.goalForm.goalAreaOfFocus"
                        render={({ field }) => {
                          return (
                            <MSBInputErrorWrapper
                              isError={goalAreaOfFocusApiClient.isError}
                              displayRefreshButton={goalAreaOfFocusApiClient.isError}
                              refetch={goalAreaOfFocusApiClient.refetch}
                              errorText={
                                "Failed to retrieve list of goal area of focus, please click refresh icon to try again."
                              }
                            >
                              <StudentProfileTextField
                                select
                                id="goalAreaOfFocus"
                                label={
                                  goalAreaOfFocusApiClient.isLoading
                                    ? "Loading options..."
                                    : "Goal Area Of Focus"
                                }
                                disabled={
                                  goalAreaOfFocusApiClient.isLoading ||
                                  goalAreaOfFocusApiClient.isError
                                }
                                value={field.value ? field.value.id : ""}
                                onChange={(e) => {
                                  const goalAreaOfFocus =
                                    goalAreaOfFocusApiClient.data?.areasOfFocus?.find(
                                      (areaOfFocus) => areaOfFocus.id === e.target.value,
                                    );
                                  field.onChange(goalAreaOfFocus);
                                }}
                                helperText={
                                  errors?.goalsAndObjectives?.goalForm?.goalAreaOfFocus?.message ??
                                  errors.goalsAndObjectives?.goalForm?.goalAreaOfFocus?.id?.message
                                }
                                FormHelperTextProps={{
                                  error:
                                    !!errors?.goalsAndObjectives?.goalForm?.goalAreaOfFocus
                                      ?.message ||
                                    !!errors.goalsAndObjectives?.goalForm?.goalAreaOfFocus?.id
                                      ?.message,
                                }}
                              >
                                {goalAreaOfFocusApiClient.data?.areasOfFocus?.map((campus) => (
                                  <MenuItem key={campus.id} value={campus.id}>
                                    {campus.name}
                                  </MenuItem>
                                ))}
                              </StudentProfileTextField>
                            </MSBInputErrorWrapper>
                          );
                        }}
                      />
                    ),
                  },
                ]}
              />
              <InputFieldGrid
                items={[
                  {
                    key: "goal start date",
                    content: (
                      <Controller
                        control={control}
                        name={`goalsAndObjectives.goalForm.startDate`}
                        render={({ field, fieldState: { error } }) => {
                          return (
                            <DatePicker
                              label={`Goal Start Date`}
                              value={field.value ? dayjs(field.value) : null}
                              ref={field.ref}
                              onChange={(date) => {
                                field.onChange(date);
                              }}
                              slots={{
                                textField: StudentProfileTextField,
                              }}
                              slotProps={{
                                textField: {
                                  helperText: error?.message,
                                  onBlur: field.onBlur,
                                  FormHelperTextProps: {
                                    error: !!error?.message,
                                  },
                                },
                              }}
                            />
                          );
                        }}
                      />
                    ),
                  },

                  {
                    key: "goal end date",
                    content: (
                      <Controller
                        control={control}
                        name={`goalsAndObjectives.goalForm.endDate`}
                        render={({ field, fieldState: { error } }) => {
                          return (
                            <DatePicker
                              label={`Goal End Date`}
                              value={field.value ? dayjs(field.value) : null}
                              ref={field.ref}
                              onChange={(date) => {
                                field.onChange(date);
                              }}
                              slots={{
                                textField: StudentProfileTextField,
                              }}
                              slotProps={{
                                textField: {
                                  helperText: error?.message,
                                  FormHelperTextProps: {
                                    error: !!error?.message,
                                  },
                                  onBlur: field.onBlur,
                                },
                              }}
                            />
                          );
                        }}
                      />
                    ),
                  },
                ]}
              />
              <StudentProfileTextField
                id="goal description"
                label="Measurable Annual Goal Text"
                {...register("goalsAndObjectives.goalForm.description")}
                helperText={errors.goalsAndObjectives?.goalForm?.description?.message}
                FormHelperTextProps={{
                  error: !!errors.goalsAndObjectives?.goalForm?.description?.message,
                }}
                multiline
                rows={6}
              />
            </Box>
          </Box>
          <Divider />
          <Box pb={4}>
            <Typography variant="h6" mb={2} fontWeight={700}>
              Objective Information |{" "}
              <Box component={"span"} sx={{ color: "primary.main", fontWeight: 700 }}>
                Total: {goalObjectives?.length ?? 0}
              </Box>
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 4 }}>
              {goalObjectives?.map((objective, i) => {
                return (
                  <Box
                    key={objective.internalId ?? i}
                    sx={{ display: "flex", flexDirection: "column", gap: 2 }}
                  >
                    <InputFieldGrid
                      items={[
                        {
                          key: "objectiveNumber",
                          content: (
                            <StudentProfileTextField
                              id="objectiveNumber"
                              label="Objective Number"
                              {...register(`goalsAndObjectives.goalForm.objectives.${i}.number`)}
                              helperText={
                                errors.goalsAndObjectives?.goalForm?.objectives?.[i]?.number
                                  ?.message
                              }
                              FormHelperTextProps={{
                                error:
                                  !!errors.goalsAndObjectives?.goalForm?.objectives?.[i]?.number
                                    ?.message,
                              }}
                            />
                          ),
                        },
                      ]}
                    />
                    <StudentProfileTextField
                      id="objective number"
                      label="Objective Text"
                      {...register(`goalsAndObjectives.goalForm.objectives.${i}.description`)}
                      helperText={
                        errors.goalsAndObjectives?.goalForm?.objectives?.[i]?.description?.message
                      }
                      FormHelperTextProps={{
                        error:
                          !!errors.goalsAndObjectives?.goalForm?.objectives?.[i]?.description
                            ?.message,
                      }}
                      multiline
                      rows={6}
                    />
                  </Box>
                );
              })}
            </Box>
            <Button
              sx={{ my: 2, borderRadius: 0, p: 2 }}
              disabled={
                goalObjectives?.[goalObjectives.length - 1]?.description === "" ||
                goalObjectives?.[goalObjectives.length - 1]?.number === ""
              }
              onClick={() => {
                const updatedObjectives = produce(goalObjectives ?? [], (draft) => {
                  draft.push({
                    internalId: crypto.randomUUID(),
                    description: "",
                    number: "",
                  });
                });
                setValue("goalsAndObjectives.goalForm.objectives", updatedObjectives);
              }}
            >
              {(goalObjectives ?? []).length > 0 ? "Add Another Objective" : "Add Objective"}
            </Button>
          </Box>
        </Box>
      </Box>
      {/* AUXILIARY COMPONENTS */}
      <StudentProfileUnsavedChangesModal
        open={backBtnClicked}
        message="You have unsaved changes on this goal/objectives form. Are you sure you want to leave this page? Any unsaved changes will be lost."
        onClose={function (): void {
          handleBackBtnClick({
            resetClickState: true,
          });
        }}
        onConfirm={async function (): Promise<void> {
          const submitted = await onSubmitGoalsAndObj();

          if (submitted) {
            handleBackBtnClick({ ignoreIsDirty: true });
          } else {
            setShowValidationErrorModal(true);
          }
        }}
        onReject={function (): void {
          handleBackBtnClick({
            ignoreIsDirty: true,
          });
        }}
      />
      <QueryStatusModal
        isOpen={showValidationErrorModal}
        status={"error"}
        onSettledClose={() => {
          setShowValidationErrorModal(false);
        }}
        content={{
          successTitle: "Success!",
          successBody: "Student profile updated successfully.",
          errorTitle: "Error!",
          errorBody:
            "Encountered validation errors while attempting to save goal, Please review from and try again.",
          pendingTitle: "Saving student profile...",
        }}
      />
    </>
  );
}
