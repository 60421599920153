import {
  Alert,
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
  Tooltip,
  Typography,
} from "@mui/material";
import useStudentProfileContext from "../hooks/use_student_profile_context";
import { Controller } from "react-hook-form";
import produce from "immer";
import InputFieldGrid from "../components/input_field_grid";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import HistoricalRecordsDialog, {
  HistoricalRecordModalProps,
} from "../../../../../fortitude/components/historical_records_modal";
import { useEffect, useState } from "react";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import usePrescribedServiceAreasData from "../hooks/medicaid_info_tab/use_prescribed_service_areas_data";
import StudentProfileTextField from "../components/student_profile_text_field";
import isInState from "../utils/is_in_state";
import StudentImportDisabledInputTooltip from "../components/student_import_disabled_input_tooltip";
import PrescribedServiceArea from "../components/medicaid_info/prescribed_service_area";
import PrescribedServiceAreaSkeleton from "../components/medicaid_info/prescribed_service_area_skeleton";

export default function MedicaidInfoTab() {
  const {
    studentProfileForm: {
      control,
      register,
      setValue,
      watch,
      formState: { errors },
    },
    queryStudentProfileApiClient: { data: studentProfileData },
    globalState: { stateInUs },
  } = useStudentProfileContext();

  const notInNH = isInState(stateInUs, "TX");
  const isStudentImportFromLegacy = studentProfileData?.createdBy === 1; //this flag is responsible for disabling input fields when the student is imported from legacy

  const [openHistoricalRecordsDialog, setOpenHistoricalRecordsDialog] = useState<{
    open: boolean;
    title: string;
    subTitle: string;
    noRecordsText?: string;
    record: HistoricalRecordModalProps[];
  }>({
    open: false,
    title: "",
    subTitle: "",
    noRecordsText: "",
    record: [],
  });

  const { serviceAreaOptionsQueryClient, prescribingProviderOptionsQueryClient } =
    usePrescribedServiceAreasData({
      stateInUs,
      clientId: studentProfileData?.client?.id ?? "",
    });

  const prescribedServiceAreas = watch("medicaidInfo.prescribedServiceAreas") ?? [];

  /**
   * Initializes the prescribed service areas array in the hook form based on the available service areas options returned from the backend.
   * We also sanitize the data to remove any bad data that does not have a service area object, as this would cause our validation to fail.
   *
   * This effect runs whenever the `serviceAreaOptionsQueryClient.data` changes. It iterates through each service area
   * and checks if it already exists in the `prescribedServiceAreas` array. If a service area does not exist, it creates
   * a new prescribed service area object with default values and adds it to the `prescribedServiceAreas` array.
   *
   * The new prescribed service area object includes:
   * - `decision`: Default value set to 0.
   * - `startDate`: Default value set to null.
   * - `endDate`: Default value set to null.
   * - `prescribingProvider`: Default value set to null.
   * - `serviceArea`: An object containing the `id` and `name` of the service area.
   *
   * The updated `prescribedServiceAreas` array is then set in the form state using the `setValue` function.
   *
   * @param {Array} serviceAreaOptionsQueryClient.data - The array of available service areas.
   * @param {Array} prescribedServiceAreas - The current array of prescribed service areas in the form state.
   * @param {Function} setValue - The function to update the form state.
   */
  useEffect(() => {
    if (serviceAreaOptionsQueryClient.isError) return;
    if (serviceAreaOptionsQueryClient.isLoading) return;
    const populatedPrescribedServiceAreas = produce(
      prescribedServiceAreas ?? [],
      (populatedPrescribedServiceAreasDraft) => {
        serviceAreaOptionsQueryClient.data.forEach((serviceArea) => {
          const prescribedServiceAreaIndex = populatedPrescribedServiceAreasDraft?.findIndex(
            (psa) => psa?.serviceArea?.id === serviceArea.id,
          );

          if (prescribedServiceAreaIndex === -1) {
            const newPrescribedServiceArea = produce(
              {} as NonNullable<typeof populatedPrescribedServiceAreasDraft>[number],
              (draft) => {
                draft.id = crypto.randomUUID();
                draft.decision = 2;
                draft.startDate = null;
                draft.endDate = null;
                draft.prescribingProvider = null;
                draft.serviceArea = {
                  id: serviceArea.id!,
                  name: serviceArea.name,
                };
              },
            );
            populatedPrescribedServiceAreasDraft.push(newPrescribedServiceArea);
          }
        });
      },
    );

    // remove bad data, this would remove prescribed service areas that do not have service area objects
    const filteredPrescribedServiceAreas = produce(
      populatedPrescribedServiceAreas ?? [],
      (draft) => {
        return draft.filter((psa) => psa.serviceArea !== null);
      },
    );

    setValue("medicaidInfo.prescribedServiceAreas", filteredPrescribedServiceAreas);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceAreaOptionsQueryClient.status]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 3,
        }}
      >
        {/* eligibility section */}
        <Box>
          <Typography variant="h5">
            Eligibility
            <Tooltip title="Historical Record" placement="right" arrow>
              <IconButton
                color="primary"
                onClick={() =>
                  setOpenHistoricalRecordsDialog({
                    open: true,
                    title: "Historical Eligibility Records",
                    subTitle: "Below are this student’s historical Medicaid eligibility dates.",
                    noRecordsText: "No historical eligibility records found",
                    record: (studentProfileData?.spedDossier?.eligibilityRecords ?? []).map(
                      (eligibilityR) => {
                        return {
                          name: eligibilityR.status === 0 ? "Eligible" : "Not Eligible",
                          dateRange: ` ${
                            eligibilityR.startDate
                              ? dayjs(new Date(eligibilityR.startDate)).toISOString().slice(0, 10)
                              : "N/A"
                          } - ${
                            eligibilityR.endDate
                              ? dayjs(new Date(eligibilityR.endDate)).toISOString().slice(0, 10)
                              : "N/A"
                          }`,
                          onDelete: () => {
                            console.log("delete eligibility record", eligibilityR);
                          },
                        };
                      },
                    ),
                  })
                }
              >
                <FolderOpenIcon />
              </IconButton>
            </Tooltip>
          </Typography>
          <Controller
            control={control}
            name="medicaidInfo.eligibilityRecord"
            render={({ field, fieldState: { error } }) => {
              return (
                <Box sx={{ mb: 2 }}>
                  <StudentImportDisabledInputTooltip show={isStudentImportFromLegacy} state={stateInUs}>
                    <RadioGroup
                      row
                      name="radio-buttons-group"
                      value={field.value?.status === 0 ? "yes" : "no"}
                      onChange={(e) => {
                        const value = e.target.value === "yes" ? 0 : 1;
                        const newEligibilityRecord = produce(field.value! ?? {}, (draft) => {
                          draft.status = value;
                          draft.startDate = new Date();
                          draft.endDate = null;
                        });
                        field.onChange(newEligibilityRecord);
                      }}
                    >
                      <FormControlLabel
                        disabled={isStudentImportFromLegacy}
                        value="yes"
                        control={<Radio size="small" />}
                        label="Yes"
                      />
                      <FormControlLabel
                        disabled={isStudentImportFromLegacy}
                        value="no"
                        control={<Radio size="small" />}
                        label="No"
                      />
                    </RadioGroup>
                    <FormHelperText error={!!error} sx={{ color: "error.main" }}>
                      {error?.message}
                    </FormHelperText>
                  </StudentImportDisabledInputTooltip>
                </Box>
              );
            }}
          />
          <InputFieldGrid
            items={[
              {
                key: "medicaid id",
                content: (
                  <StudentImportDisabledInputTooltip
                    fullwidth
                    show={isStudentImportFromLegacy}
                    state={stateInUs}
                  >
                    <StudentProfileTextField
                      id="medicaidId"
                      label="Medicaid ID"
                      disabled={isStudentImportFromLegacy}
                      {...register("generalInformation.medicaidId")}
                      helperText={errors.generalInformation?.medicaidId?.message}
                      FormHelperTextProps={{
                        error: !!errors.generalInformation?.medicaidId?.message,
                      }}
                    />
                  </StudentImportDisabledInputTooltip>
                ),
              },
            ]}
          />
        </Box>
        <Divider />
        {/* parental consent section */}
        <Box>
          <Typography variant="h5" mb={1}>
            Parental Consent
            <Tooltip title="Parental Consent Records" placement="right" arrow>
              <IconButton
                color="primary"
                onClick={() =>
                  setOpenHistoricalRecordsDialog({
                    open: true,
                    title: "Historical Parental Consent Records",
                    subTitle: "Below are this student’s historical Parental Consent dates.",
                    noRecordsText: "No historical Parental consent records found",
                    record: (studentProfileData?.spedDossier?.consent ?? []).map(
                      (consentRecord) => {
                        return {
                          name: "Consent",
                          dateRange: `${
                            consentRecord.startDate
                              ? dayjs(new Date(consentRecord.startDate)).toISOString().slice(0, 10)
                              : "N/A"
                          } - ${
                            consentRecord.endDate
                              ? dayjs(new Date(consentRecord.endDate)).toISOString().slice(0, 10)
                              : "N/A"
                          }`,
                          onDelete: () => {
                            console.log("delete eligibility record", consentRecord);
                          },
                        };
                      },
                    ),
                  })
                }
              >
                <FolderOpenIcon />
              </IconButton>
            </Tooltip>
          </Typography>

          <InputFieldGrid
            items={[
              {
                sm: 5.5,
                lg: 3.85,
                key: "consent data",
                content: (
                  <Controller
                    control={control}
                    name={`medicaidInfo.consentRecord.startDate`}
                    render={({ field, fieldState: { error } }) => {
                      return (
                        <StudentImportDisabledInputTooltip
                          fullwidth
                          show={isStudentImportFromLegacy}
                          state={stateInUs}
                        >
                          <DatePicker
                            disabled={isStudentImportFromLegacy}
                            label={`Consent Start Date`}
                            value={field.value ? dayjs(field.value) : null}
                            ref={field.ref}
                            onChange={(date) => {
                              field.onChange(date);
                              setValue("medicaidInfo.consentRecord.decision", 0);
                            }}
                            slots={{
                              textField: StudentProfileTextField,
                            }}
                            slotProps={{
                              textField: {
                                FormHelperTextProps: {
                                  error: !!error?.message,
                                },
                                helperText: error?.message,
                                onBlur: field.onBlur,
                              },
                            }}
                          />
                        </StudentImportDisabledInputTooltip>
                      );
                    }}
                  />
                ),
              },
              {
                xs: "auto",
                lg: "auto",
                md: "auto",
                sm: "auto",
                key: "consent decision",
                content: <Box sx={{ py: "9px" }}>OR</Box>,
              },
              {
                sm: 5.5,
                lg: 3.85,
                key: "refused data",
                content: (
                  <Controller
                    control={control}
                    name={`medicaidInfo.consentRecord.endDate`}
                    render={({ field, fieldState: { error } }) => {
                      return (
                        <StudentImportDisabledInputTooltip
                          fullwidth
                          show={isStudentImportFromLegacy}
                          state={stateInUs}
                        >
                          <DatePicker
                            label={`Refusal Date`}
                            disabled={isStudentImportFromLegacy}
                            value={field.value ? dayjs(field.value) : null}
                            ref={field.ref}
                            onChange={(date) => {
                              field.onChange(date);
                              setValue("medicaidInfo.consentRecord.decision", 2);
                            }}
                            slots={{
                              textField: StudentProfileTextField,
                            }}
                            slotProps={{
                              textField: {
                                FormHelperTextProps: {
                                  error: !!error?.message,
                                },
                                helperText: error?.message,
                                onBlur: field.onBlur,
                              },
                            }}
                          />
                        </StudentImportDisabledInputTooltip>
                      );
                    }}
                  />
                ),
              },
            ]}
          />
        </Box>
        <Divider />
        {/* prescriptions section */}
        <Box>
          <Typography variant="h5" mb={1}>
            Prescriptions
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
            {serviceAreaOptionsQueryClient.isSuccess &&
              (prescribedServiceAreas ?? []).map(
                (prescribedServiceArea, prescribedServiceAreaIndex) => {
                  // we only want to render prescribed service areas that are options returned from the backend
                  const isPrescribedServiceAreaAnOption =
                    serviceAreaOptionsQueryClient.data?.findIndex(
                      (psa) => psa?.id === prescribedServiceArea?.serviceArea?.id,
                    ) !== -1;
                  if (!isPrescribedServiceAreaAnOption) return null;

                  return (
                    <PrescribedServiceArea
                      key={
                        prescribedServiceArea.id ??
                        prescribedServiceArea?.serviceArea?.id ??
                        prescribedServiceAreaIndex
                      }
                      control={control}
                      serviceAreaName={prescribedServiceArea?.serviceArea?.name ?? "N/A"}
                      formInputIndex={prescribedServiceAreaIndex}
                      disbaleIputFields={isStudentImportFromLegacy}
                      stateInUs={stateInUs}
                      prescribingProviderInput={{
                        prescribingProvidersOptionsApiClient: prescribingProviderOptionsQueryClient,
                      }}
                    />
                  );
                },
              )}
            {serviceAreaOptionsQueryClient.isLoading &&
              Array.from({ length: 3 }).map((_, index) => {
                return <PrescribedServiceAreaSkeleton key={index + index} />;
              })}
            {prescribedServiceAreas.length === 0 && !serviceAreaOptionsQueryClient.isError && (
              <Alert severity="info">No prescribed service areas found</Alert>
            )}
            {serviceAreaOptionsQueryClient.isError && (
              <Alert severity="error">Failed to load prescribed service areas</Alert>
            )}
          </Box>
        </Box>
        <Divider />
        {/* personal care services section */}
        <Box sx={{ display: notInNH ? "block" : "none" }}>
          <Typography variant="h5" mb={1}>
            Personal Care Services
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Controller
              control={control}
              name="medicaidInfo.personalCareServicesDecision"
              render={({ field, fieldState: { error } }) => {
                return (
                  <FormControl sx={{ mb: 2 }}>
                    <FormLabel id="personal-care-services-iep">
                      Does{" "}
                      <Box component={"span"} sx={{ color: "primary.main", fontWeight: 700 }}>
                        {studentProfileData?.firstName} {studentProfileData?.lastName}
                      </Box>{" "}
                      have Personal Care Services ordered in the IEP?
                    </FormLabel>
                    <RadioGroup
                      row
                      name="personal-care-services-iep"
                      value={field.value ?? 2}
                      onChange={(e) => {
                        const personalCareServicesDecision: number = Number(e.target.value);

                        field.onChange(personalCareServicesDecision);
                      }}
                    >
                      <FormControlLabel value={0} control={<Radio size="small" />} label="Yes" />
                      <FormControlLabel value={1} control={<Radio size="small" />} label="No" />
                      <FormControlLabel
                        value={2}
                        control={<Radio size="small" />}
                        label="None Selected"
                      />
                    </RadioGroup>
                    <FormHelperText error={!!error} sx={{ color: "error.main" }}>
                      {error?.message}
                    </FormHelperText>
                  </FormControl>
                );
              }}
            />
            <Controller
              control={control}
              name="medicaidInfo.personalCareSupplement"
              render={({ field, fieldState: { error } }) => {
                const valueMap: { [key: number]: boolean | null } = {
                  0: true,
                  1: false,
                  2: null,
                };

                const keyMap: { [key: string]: number } = {
                  true: 0,
                  false: 1,
                  null: 2,
                };

                return (
                  <FormControl sx={{ mb: 2 }}>
                    <FormLabel id="personal-care-services-iep">
                      Does{" "}
                      <Box component={"span"} sx={{ color: "primary.main", fontWeight: 700 }}>
                        {studentProfileData?.firstName} {studentProfileData?.lastName}
                      </Box>{" "}
                      have Personal Care Supplement?
                    </FormLabel>
                    <RadioGroup
                      row
                      name="personal-care-services-iep"
                      value={keyMap[String(field.value)]}
                      onChange={(e) => {
                        const value = parseInt(e.target.value);
                        field.onChange(valueMap[value]);
                      }}
                    >
                      <FormControlLabel value={0} control={<Radio size="small" />} label="Yes" />
                      <FormControlLabel value={1} control={<Radio size="small" />} label="No" />
                      <FormControlLabel
                        value={2}
                        control={<Radio size="small" />}
                        label="None Selected"
                      />
                    </RadioGroup>
                    <FormHelperText error={!!error} sx={{ color: "error.main" }}>
                      {error?.message}
                    </FormHelperText>
                  </FormControl>
                );
              }}
            />
          </Box>
        </Box>
        <Divider sx={{ display: notInNH ? "block" : "none" }} />
        {/* transportation services section */}
        <Box>
          <Typography variant="h5" mb={1}>
            Transportation Services
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
            <Controller
              control={control}
              name="medicaidInfo.specializedTransportationDecision"
              render={({ field, fieldState: { error } }) => {
                return (
                  <FormControl>
                    <FormLabel id="specialized-transportation-services-iep">
                      Does{" "}
                      <Box component={"span"} sx={{ color: "primary.main", fontWeight: 700 }}>
                        {studentProfileData?.firstName} {studentProfileData?.lastName}
                      </Box>{" "}
                      have Specialized Transportation Services ordered in the IEP?
                    </FormLabel>
                    <RadioGroup
                      row
                      name="specialized-transportation-services-iep"
                      value={field.value ?? 2}
                      onChange={(e) => {
                        const specializedTransportationServices: number = Number(e.target.value);

                        field.onChange(specializedTransportationServices);
                      }}
                    >
                      <FormControlLabel value={0} control={<Radio size="small" />} label="Yes" />
                      <FormControlLabel value={1} control={<Radio size="small" />} label="No" />
                      <FormControlLabel
                        value={2}
                        control={<Radio size="small" />}
                        label="None Selected"
                      />
                    </RadioGroup>
                    <FormHelperText error={!!error} sx={{ color: "error.main" }}>
                      {error?.message}
                    </FormHelperText>
                  </FormControl>
                );
              }}
            />
            <Controller
              control={control}
              name="medicaidInfo.personalCareServicesOrderedOnTheBusDecision"
              render={({ field, fieldState: { error } }) => {
                return (
                  <FormControl>
                    <FormLabel id="personal-care-order-on-the-bus">
                      Does{" "}
                      <Box component={"span"} sx={{ color: "primary.main", fontWeight: 700 }}>
                        {studentProfileData?.firstName} {studentProfileData?.lastName}
                      </Box>{" "}
                      {notInNH
                        ? "have Personal Care Services ordered on the bus?"
                        : "have Rehabilitative Assistance Services ordered on the bus?"}
                    </FormLabel>
                    <RadioGroup
                      row
                      name="personal-care-order-on-the-bus"
                      value={field.value ?? 2}
                      onChange={(e) => {
                        const personalCareOnTheBus: number = Number(e.target.value);
                        field.onChange(personalCareOnTheBus);
                      }}
                    >
                      <FormControlLabel value={0} control={<Radio size="small" />} label="Yes" />
                      <FormControlLabel value={1} control={<Radio size="small" />} label="No" />
                      <FormControlLabel
                        value={2}
                        control={<Radio size="small" />}
                        label="None Selected"
                      />
                    </RadioGroup>
                    <FormHelperText error={!!error} sx={{ color: "error.main" }}>
                      {error?.message}
                    </FormHelperText>
                  </FormControl>
                );
              }}
            />
            {notInNH && (
              <Controller
                control={control}
                name="medicaidInfo.speciallyAdjustedVehicle"
                render={({ field, fieldState: { error } }) => {
                  const valueMap: { [key: number]: boolean | null } = {
                    0: true,
                    1: false,
                    2: null,
                  };

                  const keyMap: { [key: string]: number } = {
                    true: 0,
                    false: 1,
                    null: 2,
                  };

                  return (
                    <FormControl>
                      <FormLabel id="specially-adjusted-vehicle">
                        Does{" "}
                        <Box component={"span"} sx={{ color: "primary.main", fontWeight: 700 }}>
                          {studentProfileData?.firstName} {studentProfileData?.lastName}
                        </Box>{" "}
                        require a specially adapted vehicle?
                      </FormLabel>
                      <RadioGroup
                        row
                        name="specially-adjusted-vehicle"
                        value={keyMap[String(field.value)]}
                        onChange={(e) => {
                          const value = parseInt(e.target.value);
                          field.onChange(valueMap[value]);
                        }}
                      >
                        <FormControlLabel value={0} control={<Radio size="small" />} label="Yes" />
                        <FormControlLabel value={1} control={<Radio size="small" />} label="No" />
                        <FormControlLabel
                          value={2}
                          control={<Radio size="small" />}
                          label="None Selected"
                        />
                      </RadioGroup>
                      <FormHelperText error={!!error} sx={{ color: "error.main" }}>
                        {error?.message}
                      </FormHelperText>
                    </FormControl>
                  );
                }}
              />
            )}
          </Box>
        </Box>
        <Divider />
        {/* medicaid billing section */}
        <Box>
          <Typography variant="h5" mb={1}>
            Medicaid Billing Dates
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
            <Controller
              control={control}
              name="medicaidInfo.billingBlockTerm"
              render={({ field, fieldState: { error } }) => {
                const valueMap: { [key: number]: boolean | null } = {
                  0: true,
                  1: false,
                  2: null,
                };

                const keyMap: { [key: string]: number } = {
                  true: 0,
                  false: 1,
                  null: 2,
                };
                return (
                  <Box>
                    <FormControl>
                      <FormLabel id="medicaid-billing-block-term-boolean">
                        Does{" "}
                        <Box component={"span"} sx={{ color: "primary.main", fontWeight: 700 }}>
                          {studentProfileData?.firstName} {studentProfileData?.lastName}
                        </Box>{" "}
                        have Specialized Transportation Services ordered in the IEP?
                      </FormLabel>
                      <RadioGroup
                        row
                        name="medicaid-billing-block-term-boolean"
                        value={keyMap[String(field.value?.blockBilling ?? null)]}
                        onChange={(e) => {
                          const isBlockBilling: number = Number(e.target.value);
                          const updatedBillingTerm = produce(field.value ?? {}, (draft) => {
                            draft.blockBilling = valueMap[isBlockBilling];
                            draft.blockBillingStartDate = draft.blockBillingStartDate ?? null;
                            draft.blockBillingEndDate = draft.blockBillingEndDate ?? null;
                          });
                          field.onChange(updatedBillingTerm);
                        }}
                      >
                        <FormControlLabel value={0} control={<Radio size="small" />} label="Yes" />
                        <FormControlLabel value={1} control={<Radio size="small" />} label="No" />
                        <FormControlLabel
                          value={2}
                          control={<Radio size="small" />}
                          label="None Selected"
                        />
                      </RadioGroup>
                      <FormHelperText error={!!error} sx={{ color: "error.main" }}>
                        {error?.message}
                      </FormHelperText>
                    </FormControl>
                    <InputFieldGrid
                      gridContainerProps={{
                        sx: { mb: 2 },
                      }}
                      items={[
                        {
                          key: "medicaid billing start date",
                          content: (
                            <DatePicker
                              label={`Start Date`}
                              value={
                                field.value?.blockBillingStartDate
                                  ? dayjs(field.value.blockBillingStartDate)
                                  : null
                              }
                              ref={field.ref}
                              onChange={(date) => {
                                const updatedBillingTerm = produce(field.value ?? {}, (draft) => {
                                  draft.blockBillingStartDate = dayjs(date).toDate();
                                });
                                field.onChange(updatedBillingTerm);
                              }}
                              slots={{
                                textField: StudentProfileTextField,
                              }}
                              slotProps={{
                                textField: {
                                  FormHelperTextProps: {
                                    error: !!error?.message,
                                  },
                                  helperText: error?.message,
                                  onBlur: field.onBlur,
                                },
                              }}
                            />
                          ),
                        },
                      ]}
                    />
                    <InputFieldGrid
                      items={[
                        {
                          key: "medicaid billing start date",
                          content: (
                            <DatePicker
                              label={`End Date`}
                              value={
                                field.value?.blockBillingEndDate
                                  ? dayjs(field.value.blockBillingEndDate)
                                  : null
                              }
                              ref={field.ref}
                              onChange={(date) => {
                                const updatedBillingTerm = produce(field.value ?? {}, (draft) => {
                                  draft.blockBillingEndDate = dayjs(date).toDate();
                                });
                                field.onChange(updatedBillingTerm);
                              }}
                              slots={{
                                textField: StudentProfileTextField,
                              }}
                              slotProps={{
                                textField: {
                                  FormHelperTextProps: {
                                    error: !!error?.message,
                                  },
                                  helperText: error?.message,
                                  onBlur: field.onBlur,
                                },
                              }}
                            />
                          ),
                        },
                      ]}
                    />
                  </Box>
                );
              }}
            />
          </Box>
        </Box>
        <Divider />
      </Box>
      <HistoricalRecordsDialog
        isOpen={openHistoricalRecordsDialog.open}
        title={openHistoricalRecordsDialog.title}
        canDelete={false}
        noRecordsText={openHistoricalRecordsDialog.noRecordsText}
        record={openHistoricalRecordsDialog.record}
        subTitle={openHistoricalRecordsDialog.subTitle}
        onClose={() =>
          setOpenHistoricalRecordsDialog({ ...openHistoricalRecordsDialog, open: false })
        }
      />
    </>
  );
}
