import { Route, Routes } from "react-router-dom";
import PageNotFound from "./views/404";
import { NavLayout } from "./layouts/NavLayout";
import { ROUTES_XLOGS } from "./constants/URLs";
import StudentManager from "./views/students/views/manager/manager";
import XLogsCalendar from "./views/calendar/calendar";
import Notator from "./views/notator/notator";
import StudentResponsePage from "./views/students/profile";
import UserProfile from "./views/user/profile";
import { NotatorToolsProvider } from "./views/notator/tools";
import { CssBaseline } from "@mui/material";
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import withAppInsights, { reactPlugin } from "./AppInsights/appInsights.js";
import UnpostedSessionsLayout from "./views/unposted_sessions/unposted_sessions_layout";
import UnpostedSessionsBatchPostProvider from "./views/unposted_sessions/batch-post/providers/unposted_sessions_batch_post_provider";
import UnpostedSessionsBatchPostView from "./views/unposted_sessions/batch-post/unposted_sessions_batch_post_view";
import UserApprovalView from "./views/admin/views/user_management/views/user_approvals/veiws/user_approval_view";
import UserApprovalsProvider from "./views/admin/views/user_management/views/user_approvals/context/userApprovalsProvider";
import StaffDirectoryManagerLayout from "./views/admin/views/user_management/views/staff_directory_manager/staff_directory_manager_layout";
import UserManagementLayout from "./views/admin/views/user_management/user_management_layout";
import UserManagementProvider from "./views/admin/views/user_management/context/provider/user_management_provider";
import StaffDirectoryManagerHomePage from "./views/admin/views/user_management/views/staff_directory_manager/views/staff_directory_manager_home_page";
import StaffDirectoryManagerProfilePage from "./views/admin/views/user_management/views/staff_directory_manager/views/profile_page.tsx/staff_directory_manager_profile_page";
import CampusInformation from "./views/admin/views/campus_info/campus_info";
import StaffDirectoryHomePageProvider from "./views/admin/views/user_management/views/staff_directory_manager/context/staff_directory_home_page_provider";
import BuildInfo from "./views/BuildInfo";
import { StudentProfileContextProvider as StudentProfileContextProviderDeprecated } from "./views/students/context/context";
import StaticReportsView from "./views/reports/views/static_reports/static_reports_view";
import FortitudeView from "./fortitude/docs";
import { CalendarContextProvider } from "./views/calendar/context/context";
import ReportsOutlet from "./views/reports/reports_outlet";
import { AccountStudentReport } from "./views/reports/views/account_student_report/account_student_report";
import AdminProtectedRoute from "./AdminProtectedRoute";
import StudentMerge from "./views/admin/views/student_merge/student_merge";
import { DistrictProfile } from "./views/admin/views/district_profile";
import { FinancialDashboardProvider } from "./views/admin/views/district_profile/context/provider/financialDashboardProvider";
import UnpostedSessionsProvider from "./views/unposted_sessions/context/unposted_sessions_provider";
import UnpostedSessionsView from "./views/unposted_sessions/views/unposted_sessions_view";
import UnpostedSessionsNotatorView from "./views/unposted_sessions/views/unposted_sessions_view_notator_view";
import { PrescribingProvider } from "./views/admin/views/prescibing_provider";
import PrescribingProviderContextProvider from "./views/admin/views/prescibing_provider/context/provider/PrescribingProviderContextProvider";
import { ProgressReporting } from "./views/reports/views/progress_reporting";
import { ProgressSummary } from "./views/reports/views/progress_reporting/progress_summary_view";
import ProgressReportingContextProvider from "./views/reports/views/progress_reporting/context/provider/ProgressReportingContextProvider";
import ProviderNotFoundError from "./design/high-level/Error/ProviderNotFoundError";
import ThankYouModal from "./design/modal_templates/ThankYouModal";
import RemoveProviderConfirmationModal from "./design/modal_templates/RemoveProviderConfirmationModal";
import { SimpleAttentionModal } from "./design/modal_templates/SimpleAttentionModal";
import { ErrorEntriesModal } from "./modals/error_entries_modal";
import { SessionLogsProvider } from "./views/reports/views/session_logs/context";
import {
  ServiceReportView,
  SessionLogsLayout,
  SessionLogsView,
} from "./views/reports/views/session_logs/views";
import UnpostedSessionsReportView from "./views/reports/views/unposted_sessions_report/unposted_sessions_report_view";
import { StrictMode } from "react";
import StudentsTabLayout from "./views/students/views/students_tab_layout";
import StudentProfileContextProvider from "./views/students/views/student_profile/context/student_profile_context_provider";
import StudentProfileSuspenseBoundary from "./views/students/views/student_profile/components/student_profile_suspense_boundary";


import CaseloadManagementProvider from "./views/admin/views/caseload_management/context/caseload_management_provider";
import CaseloadManagementLayout from "./views/admin/views/caseload_management/caseload_management_layout";
import ApproverCaseloadsView from "./views/admin/views/caseload_management/views/approver_caseloads";
import { useXNGSelector } from "./context/store";

function App() {
 const v2StudentProfileEnabled = useXNGSelector(state => (state.featureFlags.flags.v2StudentProfileEnabled )) ?? false;

  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      <CssBaseline>
        <NavLayout>
          <Routes>
            <Route path={ROUTES_XLOGS.fortitude + "/*"} element={<FortitudeView />} />
            <Route
              path={ROUTES_XLOGS.calendar}
              element={
                <CalendarContextProvider>
                  <XLogsCalendar />
                </CalendarContextProvider>
              }
            />
            <Route
              path={ROUTES_XLOGS.notator + "/:sessionID"}
              element={
                <NotatorToolsProvider>
                  <Notator />
                </NotatorToolsProvider>
              }
            />
            
            <Route path={ROUTES_XLOGS.reports.index} element={<ReportsOutlet />}>
              <Route element={<StaticReportsView />} index />
              <Route
                path={ROUTES_XLOGS.reports.accountStudentReport}
                element={<AccountStudentReport />}
              />
              <Route
                path={ROUTES_XLOGS.reports.sessionLogs}
                element={
                  <SessionLogsProvider>
                    <SessionLogsLayout />
                  </SessionLogsProvider>
                }
              >
                <Route index element={<SessionLogsView />} />
                <Route path={ROUTES_XLOGS.reports.serviceReport} element={<ServiceReportView />} />
              </Route>
              <Route
                path={ROUTES_XLOGS.reports.unpostedSessionsReport}
                element={
                  <StrictMode>
                    <UnpostedSessionsReportView />
                  </StrictMode>
                }
              />
            </Route>

            <Route path={ROUTES_XLOGS._students.manager} element={<StudentsTabLayout />}>
              <Route
                path={ROUTES_XLOGS._students.profile}
                element={
                  <>
                    {v2StudentProfileEnabled ? (
                      <StrictMode>
                        <StudentProfileContextProvider/>
                      </StrictMode>
                    ) : (
                      <StudentProfileContextProviderDeprecated>
                        <PrescribingProviderContextProvider>
                          <StudentResponsePage />
                        </PrescribingProviderContextProvider>
                      </StudentProfileContextProviderDeprecated>
                    )}
                  </>
                }
                
              >
                <Route index element={<StudentProfileSuspenseBoundary />} />
              </Route>
              <Route index element={<StudentManager />} />
              {/* <Route path={ROUTES_XLOGS._students.groups} element={<StudentGroups />} /> */}
            </Route>
    
            <Route path={ROUTES_XLOGS.user} element={<UserProfile />} />
            <Route element={<AdminProtectedRoute />}>
              <Route
                index
                path={ROUTES_XLOGS._admin.mydistrictProfile}
                element={
                  <FinancialDashboardProvider>
                    <DistrictProfile />
                  </FinancialDashboardProvider>
                }
              />
              <Route
                path={ROUTES_XLOGS._admin.index}
                element={
                  <UserManagementProvider>
                    <UserManagementLayout />
                  </UserManagementProvider>
                }
              >
                <Route
                  index
                  path={ROUTES_XLOGS._admin.userApproval}
                  element={
                    <UserApprovalsProvider>
                      <UserApprovalView />
                    </UserApprovalsProvider>
                  }
                />
                <Route
                  path={ROUTES_XLOGS._admin.staffDirectoryManager}
                  element={
                    <StaffDirectoryHomePageProvider>
                      <StaffDirectoryManagerLayout />
                    </StaffDirectoryHomePageProvider>
                  }
                >
                  <Route index element={<StaffDirectoryManagerHomePage />} />
                  <Route
                    path={ROUTES_XLOGS._admin.staffDirectoryManager + "/:provider_info_id"}
                    element={<StaffDirectoryManagerProfilePage />}
                  />
                </Route>
              </Route>
              <Route path={ROUTES_XLOGS._admin.campusManagement} element={<CampusInformation />} />
              <Route path={ROUTES_XLOGS._admin.studentMerge} element={<StudentMerge />} />
              <Route
                index
                path={ROUTES_XLOGS._admin.prescribingProvider}
                element={
                  <UserManagementProvider>
                    <PrescribingProviderContextProvider>
                      <PrescribingProvider />
                    </PrescribingProviderContextProvider>
                  </UserManagementProvider>
                }
              />
              <Route path={ROUTES_XLOGS._admin.index} element={
                <CaseloadManagementProvider>
                  <CaseloadManagementLayout />
                </CaseloadManagementProvider>
              }>
                <Route path={ROUTES_XLOGS._admin.approverCaseloads} element={<ApproverCaseloadsView />} />
              </Route>
            </Route>
            <Route
              path={ROUTES_XLOGS.unposted_sessions.index}
              element={
                <UnpostedSessionsProvider>
                  <UnpostedSessionsLayout />
                </UnpostedSessionsProvider>
              }
            >
              <Route
                path={ROUTES_XLOGS.unposted_sessions.index + "/:viewId"}
                element={<UnpostedSessionsView />}
              />
              <Route
                path={ROUTES_XLOGS.unposted_sessions.notator}
                element={<UnpostedSessionsNotatorView />}
              >
                <Route
                  index
                  element={
                    <NotatorToolsProvider>
                      <Notator />
                    </NotatorToolsProvider>
                  }
                />
              </Route>
            </Route>
            <Route
              path={ROUTES_XLOGS.unposted_sessions.batch_post}
              element={
                <UnpostedSessionsBatchPostProvider>
                  <UnpostedSessionsBatchPostView />
                </UnpostedSessionsBatchPostProvider>
              }
            />

            <Route
              path={ROUTES_XLOGS.reports.progressReport}
              element={
                <UserManagementProvider>
                  <ProgressReportingContextProvider>
                    <ProgressReporting />
                  </ProgressReportingContextProvider>
                </UserManagementProvider>
              }
            />
            <Route
              path={ROUTES_XLOGS.reports.progressSummary}
              element={
                <ProgressReportingContextProvider>
                  <ProgressSummary />
                </ProgressReportingContextProvider>
              }
            />

            {/* Build */}
            <Route path="/xlogs/build" element={<BuildInfo />} />
            {/* 404 */}
            <Route path="*" element={<PageNotFound />} />
          </Routes>

          {/* MODALS | This is where we house the globally accessible, minimum necessary modals controlled by associated Redux slices. */}
          <ErrorEntriesModal />
          {/* TODO: Are the below two modals deprecated? */}
          <ThankYouModal />
          <SimpleAttentionModal />
          {/* TODO: Consider moving the below modals to their associated features */}
          <ProviderNotFoundError />
          <RemoveProviderConfirmationModal />
        </NavLayout>
      </CssBaseline>
    </AppInsightsContext.Provider>
  );
}
const TrackedApp = withAppInsights(App);

export default TrackedApp;
