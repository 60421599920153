import { Box, Typography, TextField } from "@mui/material";
import { IoSearch } from "react-icons/io5";
import { useEffect, useMemo, useState } from "react";
import {
  AddProviderInformationModal,
  DeleteSuccessModal,
  EditProviderInformationModal,
} from "../components/Modals";
import { PrescribingProviderDataGrid } from "../components/Grids";
import usePrescribingProvider from "../hooks/usePrescribingProvider";
import { API_PRESCRIBING_PROVIDER } from "../../../../../api/api";
import { GetPrescribingProvidersResponse } from "../../../../../profile-sdk";
import { PrescribingProviderActionType } from "../context/prescribing_provider_actions";
import useUserManagementContext from "../../user_management/hooks/context/use_user_management_context";
import FullPageLoadingScreen from "../../../../../design/high-level/common/full_page_loading_screen";
import { PrescribingProviderActionButton } from "../components/Buttons";

// export const test_state_name = 'NH'

const PrescribingProvider = () => {
  const [isDeleteSuccessModal, setDeleteSuccessModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const memoisedSearchQuery = useMemo(() => searchQuery, [searchQuery]);
  const { dispatch } = usePrescribingProvider();
  const client_id = useUserManagementContext().store.userManagementData.client?.id;
  const state_in_us = useUserManagementContext().store.userManagementData.stateInUs;
  const isAddingPrescribingProvider = usePrescribingProvider().state.isAdding;
  const isEditingPrescribingProvider = usePrescribingProvider().state.isEditing;
  const prescribingProvidersToBeDeleted =
    usePrescribingProvider().state.checkedPrescribingProviders;
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if (client_id && state_in_us) {
      getPrescribingProviders();
    }
  }, [memoisedSearchQuery, client_id, state_in_us]);

  const getPrescribingProviders = async () => {
    try {
      if (!client_id || !state_in_us) return;
      const response: GetPrescribingProvidersResponse =
        await API_PRESCRIBING_PROVIDER.v1PrescribingProvidersGet(client_id, state_in_us);
      dispatch({
        type: PrescribingProviderActionType.SET_PRESCRIBING_PROVIDERS_LIST,
        payload: response.prescribingProviders,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const hanleAddModalClose = () => {
    dispatch({ type: PrescribingProviderActionType.CLOSE_ADD_PRESCRIBING_PROVIDER_MODAL });
  };
  const handleEditModalClose = () => {
    dispatch({ type: PrescribingProviderActionType.CLOSE_EDIT_PRESCRIBING_PROVIDER_MODAL });
  };
  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const hanldeDeletePrescribingProvider = async () => {
    if (
      (Array.isArray(prescribingProvidersToBeDeleted) &&
        prescribingProvidersToBeDeleted.length === 0) ||
      !prescribingProvidersToBeDeleted
    ) {
      return;
    }
    setLoading(true);
    const isAllDeleted = await Promise.all(
      (prescribingProvidersToBeDeleted || []).map(async (prescribingProvider) => {
        if (prescribingProvider.id && client_id) {
          try {
            // Call the API to delete the provider
            await API_PRESCRIBING_PROVIDER.v1PrescribingProvidersIdDeletePatch(
              prescribingProvider.id,
              client_id,
              state_in_us,
            );
            return true; // Return true if deletion is successful
          } catch (error) {
            console.error(`Failed to delete provider with ID ${prescribingProvider.id}:`, error);
            return false; // Return false if there is an error
          }
        }
        return false; // Return false if id or client_id is missing
      }),
    );

    // Check if all deletions were successful
    const allDeletedSuccessfully = isAllDeleted.every((status) => status === true);
    if (allDeletedSuccessfully) {
      //handle delte logic
      setDeleteSuccessModal(true);
      getPrescribingProviders();
      setLoading(false);
    }
  };

  const handleAddPrescribingProvider = () => {
    dispatch({ type: PrescribingProviderActionType.ADD_PRESCRIBING_PROVIDER });
  };

  return (
    <>
      {isLoading ? (
        <FullPageLoadingScreen text="deleting selected prescribing providers" />
      ) : (
        <Box>
          {isDeleteSuccessModal && (
            <DeleteSuccessModal
              isOpened={isDeleteSuccessModal}
              onClose={() => setDeleteSuccessModal(false)}
            />
          )}
          {isAddingPrescribingProvider && (
            <AddProviderInformationModal
              isOpened={isAddingPrescribingProvider}
              onClose={() => hanleAddModalClose()}
            />
          )}
          {isEditingPrescribingProvider && (
            <EditProviderInformationModal
              isOpened={isEditingPrescribingProvider}
              onClose={() => handleEditModalClose()}
            />
          )}
          <Box display={"flex"} alignItems={"center"} width={"100%"}>
            <Box
              px={{ lg: 10, xs: 4 }}
              display={"flex"}
              alignItems={"center"}
              height={"144px"}
              bgcolor={"rgba(7, 89, 112, 1)"}
              width={"100%"}
              justifyContent={"space-between"}
            >
              <Typography fontWeight={400} fontSize={24} sx={{ color: "white" }}>
                Prescribing Providers(s)
              </Typography>
              <Box
                display={"flex"}
                gap={"15px"}
                alignItems={"center"}
                justifyContent={"center"}
                flexDirection={"row"}
              >
                <IoSearch size="24px" color="white" />
                <TextField
                  placeholder="Search..."
                  variant="outlined"
                  onChange={handleSearch}
                  value={searchQuery}
                  sx={{
                    mt: 2,
                    mb: 2,
                    outline: "none",
                    borderRadius: "10px",
                    background: "white",
                    "& .MuiInputBase-root": {
                      height: "28px", // Adjust the height as needed
                      width: "177px",
                    },
                    "& .MuiOutlinedInput-input": {
                      padding: "10px 14px", // Adjust the padding as needed
                    },
                    "& .MuiInputAdornment-root": {
                      height: "28px", // Ensure the adornment matches the input height
                      width: "177px",
                    },
                  }}
                />
              </Box>
            </Box>
          </Box>
          <Box px={{ lg: 10, xs: 4 }} display={"flex"} mt={4} flexDirection={"column"} gap={"8px"}>
            <Typography color={"rgba(59, 71, 82, 1)"} sx={{ fontSize: "18px", fontWeight: "700" }}>
              Administrator
            </Typography>
            <Box display="flex" flexDirection={"row"} justifyContent={"space-between"}>
              <Typography width={"40%"}>
                Administrators have the authorization to modify and update prescribing provider(s)
                information, ensuring accuracy and relevance information is logged on behalf of the
                prescribing provider(s).
              </Typography>

              <Box display={"flex"} flexDirection={"row"} gap={"20px"}>
                {/* <PrescribingProviderActionButton variant="outlined">NPI Inquiry Form Request</PrescribingProviderActionButton> */}
                <PrescribingProviderActionButton
                  onClick={handleAddPrescribingProvider}
                  variant="contained"
                >
                  Add
                </PrescribingProviderActionButton>
                <PrescribingProviderActionButton
                  onClick={hanldeDeletePrescribingProvider}
                  variant="contained"
                >
                  Delete
                </PrescribingProviderActionButton>
              </Box>
            </Box>
            {/* data grid goes here */}
            <Box mt={4}>
              <PrescribingProviderDataGrid searchQuery={memoisedSearchQuery} />
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};
export default PrescribingProvider;
