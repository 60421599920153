import { Box, Modal, Typography } from "@mui/material";
import PrescribingProviderModalRoot from "../ModalRoot/PrescribingProviderModalRoot";
import React, { useEffect, useState } from "react";
import usePrescribingProvider from "../../../hooks/usePrescribingProvider";
import { API_PRESCRIBING_PROVIDER } from "../../../../../../../api/api";
import useUserManagementContext from "../../../../user_management/hooks/context/use_user_management_context";
import {
  PatchPrescribingProviderRequest,
  GetPrescribingProvidersResponse,
} from "../../../../../../../profile-sdk";
import { PrescribingProviderActionType } from "../../../context/prescribing_provider_actions";
import FullPageLoadingScreen from "../../../../../../../design/high-level/common/full_page_loading_screen";
import { commonModalStyles } from "../common_styles";

enum InputNames {
  PROVIDER_FIRST_NAME = "firstName",
  PROVIDER_LAST_NAME = "lastName",
  NPI = "npi",
  MEDICAID = "stateMedicaidNumber",
}

const Edit: React.FC<{ isOpened: boolean; onClose: () => void }> = ({ isOpened, onClose }) => {
  const [providerInfromation, setProviderInformation] = React.useState<{
    id: string;
    firstName: string;
    lastName: string;
    npi: string;
    stateMedicaidNumber: string;
  }>({
    id: "",
    [InputNames.PROVIDER_FIRST_NAME]: "",
    [InputNames.PROVIDER_LAST_NAME]: "",
    [InputNames.NPI]: "",
    [InputNames.MEDICAID]: "",
  });
  const prescribingProviderToBeEdited = usePrescribingProvider()?.state?.prescribingProvider;
  const state_in_us = useUserManagementContext().store.userManagementData.stateInUs;
  const client = useUserManagementContext().store.userManagementData.client;
  const { dispatch } = usePrescribingProvider();
  const [isUpdating, setIsUpdating] = useState(false);
  const [error, setError] = useState<{ 
    firstName: string;
    lastName: string;
    npi: string;
    stateMedicaidNumber: string;
  }>({ 
    firstName: "",
    lastName: "",
    npi: "",
    stateMedicaidNumber: ""
  });
  const updatePrescribingProvider = async (
    updatedPrescribingProviderData: PatchPrescribingProviderRequest,
    state: string,
    clientId: string,
  ) => {
    try {
      const response = await API_PRESCRIBING_PROVIDER.v1PrescribingProvidersIdPatch(
        providerInfromation.id,
        clientId,
        state,
        updatedPrescribingProviderData,
      );
      return response;
    } catch (error) {
      console.error("failed to update prescribing provider", error);
    }
  };
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setProviderInformation({ ...providerInfromation, [e.target.name]: e.target.value.trim() });
  };

  const onSubmit = () => {
     let isError = false

     if(!providerInfromation.firstName){
      setError(prev=>({ ...prev, firstName: "Required" })); 
      isError = true
     }

     if(!providerInfromation.lastName){
      setError(prev=>({ ...prev, lastName: "Required" }));
      isError = true
     } 
     if(isError){
      return
     } 
    // handle submit here
    if (!client?.id || !client?.name) {
      console.error("Client not found >>");
      return;
    }
    setIsUpdating(true);
    const udpatedPrescribingProviderData = {
      firstName: providerInfromation.firstName,
      lastName: providerInfromation.lastName,
      npi: providerInfromation.npi,
      stateMedicaidNumber: providerInfromation.stateMedicaidNumber,
      client: {
        id: client?.id,
        name: client?.name,
        startDate: client?.startDate,
        endDate: client?.endDate,
      },
    };
    updatePrescribingProvider(udpatedPrescribingProviderData, state_in_us, client?.id).then(
      async (response) => {
        if (response && client.id) {
          const response: GetPrescribingProvidersResponse =
            await API_PRESCRIBING_PROVIDER.v1PrescribingProvidersGet(client?.id, state_in_us);
          dispatch({
            type: PrescribingProviderActionType.SET_PRESCRIBING_PROVIDERS_LIST,
            payload: response.prescribingProviders,
          });
          setIsUpdating(false);
          onClose();
        }
      },
    );
  };

  useEffect(() => {
      setProviderInformation({
        id: prescribingProviderToBeEdited?.id as string,
        firstName: prescribingProviderToBeEdited?.firstName as string,
        lastName: prescribingProviderToBeEdited?.lastName as string,
        npi: prescribingProviderToBeEdited?.npi as string,
        stateMedicaidNumber: prescribingProviderToBeEdited?.stateMedicaidNumber as string,
      });
  }, [prescribingProviderToBeEdited]);

  useEffect(() => {
    if(error.firstName && providerInfromation?.firstName){ 
      setError({ ...error, firstName: "" })
    }
    if(error.lastName && providerInfromation?.lastName){
      setError({ ...error, lastName: "" });
    }
  },[providerInfromation])

  return (
    <>
      {isUpdating ? (
        <FullPageLoadingScreen text="Updating..." />
      ) : (
        <Modal
          open={isOpened}
          onClose={onClose}
          sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
        >
          <PrescribingProviderModalRoot >
            <PrescribingProviderModalRoot.Title>
              <Typography sx={{ fontSize: "18px", fontWeight: "500", color: "black" }}>
                Edit Provider Information
              </Typography>
            </PrescribingProviderModalRoot.Title>
            <Box display={"flex"} width={"100%"} gap={"20px"} flexDirection={"row"}>
              <PrescribingProviderModalRoot.InputMember
                name={InputNames.PROVIDER_FIRST_NAME}
                error={error.firstName}
                value={providerInfromation[InputNames.PROVIDER_FIRST_NAME]}
                onChange={onChange}
                style={commonModalStyles.providerName}
                label="Provider First Name"
                placeHolder="Type First Name"
              />
              <PrescribingProviderModalRoot.InputMember
                error={error.lastName}
                name={InputNames.PROVIDER_LAST_NAME}
                value={providerInfromation[InputNames.PROVIDER_LAST_NAME]}
                onChange={onChange}
                style={commonModalStyles.providerName}
                label="Provider Last Name"
                placeHolder="Type Last Name"
              />
            </Box>
            <Box display={"flex"} mt={4} flexDirection={"row"} gap={"20px"}>
              <Box display={"flex"} width={"100%"} flexDirection={"column"} gap={"20px"}>
                <PrescribingProviderModalRoot.InputMember
                  name={InputNames.NPI}
                  value={providerInfromation[InputNames.NPI]}
                  onChange={onChange}
                  label="NPI"
                  placeHolder="Type NPI Number"
                />
                <PrescribingProviderModalRoot.ButtonOutlined onClick={onClose}>
                  Cancel
                </PrescribingProviderModalRoot.ButtonOutlined>
              </Box>
              <Box display={"flex"} width={"100%"} flexDirection={"column"} gap={"20px"}>
                <PrescribingProviderModalRoot.InputMember
                  name={InputNames.MEDICAID}
                  value={providerInfromation[InputNames.MEDICAID]}
                  onChange={onChange}
                  label="State Medicaid Number"
                  placeHolder="Type Medicaid Number"
                />
                <PrescribingProviderModalRoot.ButtonNormal onClick={onSubmit}>
                  Save
                </PrescribingProviderModalRoot.ButtonNormal>
              </Box>
            </Box>
          </PrescribingProviderModalRoot>
        </Modal>
      )}
    </>
  );
};

export default Edit;
