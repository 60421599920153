import { useMemo, useState } from 'react'
import useQueryGoalAreaOfFocusOptions from '../api/use_query_goal_area_of_focus_options';

/**
 * Custom hook to manage goal area of focus options and filter selection.
 * This allows the selection persist across goal views, also we consolidate goal area of focus related data in one place
 *
 * @param props - The properties object.
 * @param props.stateInUs - The state in the US to fetch goal area of focus options for.
 *
 * @returns An object containing:
 * - `goalAreaOfFocusFilterOptions`: An array of goal area of focus filter options.
 * - `goalAreaOfFocusOptionsApiClient`: The API client for fetching goal area of focus options.
 * - `selectedGoalAreaOfFocus`: The currently selected goal area of focus filter option.
 * - `handleChangeGoalAreaOfFocusFilter`: A function to handle changes to the selected goal area of focus filter option.
 */
export default function useGoalAreaOfFocusOptionsAndFilterSelectionHandler(props: {
    stateInUs: string
}) {
    const goalAreaOfFocusOptionsApiClient = useQueryGoalAreaOfFocusOptions({
        queryParams: { stateInUs: props.stateInUs },
    });

    const goalAreaOfFocusFilterOptions = useMemo(() => {
        return [
            { id: "all", name: "All" },
            ...(goalAreaOfFocusOptionsApiClient.data?.areasOfFocus ?? []),
        ];
    }, [goalAreaOfFocusOptionsApiClient.data]);

    const [selectedGoalAreaOfFocus, setSelectedGoalAreaOfFocus] = useState<
        (typeof goalAreaOfFocusFilterOptions)[number]
    >({ id: "all", name: "All" });

    function handleChangeGoalAreaOfFocusFilter(selectedOption: (typeof goalAreaOfFocusFilterOptions)[number]) {

        setSelectedGoalAreaOfFocus(selectedOption);
    }

    return {
        goalAreaOfFocusFilterOptions,
        goalAreaOfFocusOptionsApiClient,
        selectedGoalAreaOfFocus,
        handleChangeGoalAreaOfFocusFilter
    }
}
