import { Alert, Box, Slide, SlideProps, Snackbar } from "@mui/material";
import useStudentProfileContext from "./hooks/use_student_profile_context";
import useStudentProfileValidationFormErrorStackHandler from "./hooks/use_student_profile_validation_form_error_stack_handler";
import StudentsTabSideBar from "../../components/students_tab_side_bar";
import { ReactNode, useEffect, useState } from "react";
import StudentProfileUnsavedChangesModal from "./components/student_profile_unsaved_changes_modal";
import QueryStatusModal from "../../../../design/modal_templates/query_status_modal";

export default function StudentProfileLayout({ children }: Readonly<{ children: ReactNode }>) {
  const {
    queryStudentProfileApiClient: { isError: queryStudentProfileIsError },
    mutateStudentProfileApiClient: { status: mutateStudentProfileStatus },
    handleFormSubmit,
    studentProfileForm: { control, handleSubmit },
    navigation: { backBtnClicked, handleBackBtnClick },
  } = useStudentProfileContext();

  const { errorStack, orderedVisibleErrorKeys } = useStudentProfileValidationFormErrorStackHandler({
    formControl: control,
  });

  const [showSaveStudentProfileModal, setShowSaveStudentProfileModal] = useState(false);
  const [showSuccessToast, setShowSuccessToast] = useState<{
    open: boolean;
    message: string;
    severity: "success" | "info";
  }>({
    open: false,
    message: "",
    severity: "success",
  });
  // if there is an error in queryStudentProfile, go back to the students list page
  useEffect(() => {
    if (queryStudentProfileIsError) {
      handleBackBtnClick({
        ignoreIsDirty: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryStudentProfileIsError]);

  // if there is an error in mutateStudentProfile, show the error modal, and if there is a success, show the success toast
  useEffect(() => {
    if (mutateStudentProfileStatus === "error") {
      setShowSaveStudentProfileModal(true);
    }

    if (mutateStudentProfileStatus === "success") {
      setShowSuccessToast({
        open: true,
        message: "Student profile updated successfully.",
        severity: "success",
      });

      //if this was saved via clicking backbutton(unsaved changes modal), then we navigate back to the student manager upon success
      if (backBtnClicked) {
        handleBackBtnClick({
          ignoreIsDirty: true,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mutateStudentProfileStatus]);

  return (
    <>
      <StudentsTabSideBar>{children}</StudentsTabSideBar>
      {/* AUXILIARY COMPONENTS */}
      <Snackbar
        color={"primary.main"}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={true}
      >
        <Box display={"flex"} flexDirection={"column-reverse"} alignItems={"center"} gap={1}>
          {orderedVisibleErrorKeys.map((key) => {
            return (
              <Slide in={errorStack[key].show} direction="left" key={key} unmountOnExit>
                <Alert severity="warning" variant="filled" sx={{ width: "100%" }}>
                  {errorStack[key].message}
                </Alert>
              </Slide>
            );
          })}
        </Box>
      </Snackbar>
      <StudentProfileUnsavedChangesModal
        open={backBtnClicked}
        onClose={function (): void {
          handleBackBtnClick({
            resetClickState: true,
          });
        }}
        onConfirm={function (): void {
          if (mutateStudentProfileStatus === "pending") {
            setShowSuccessToast({
              open: true,
              message: "Please wait, Student profile update in progress...",
              severity: "info",
            });
            return;
          }
          handleSubmit(handleFormSubmit)();
        }}
        onReject={function (): void {
          handleBackBtnClick({
            ignoreIsDirty: true,
          });
        }}
      />
      <QueryStatusModal
        isOpen={showSaveStudentProfileModal}
        status={mutateStudentProfileStatus}
        onSettledClose={() => {
          setShowSaveStudentProfileModal(false);
        }}
        content={{
          successTitle: "Success!",
          successBody: "Student profile updated successfully.",
          errorTitle: "Error!",
          errorBody: "Encountered an error while updating student profile, please try again.",
          pendingTitle: "Saving student profile...",
        }}
      />
      <Snackbar
        open={showSuccessToast.open}
        onClose={() =>
          setShowSuccessToast({
            ...showSuccessToast,
            open: false,
          })
        }
        TransitionComponent={Slide}
        TransitionProps={{ direction: "left" } as SlideProps}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        autoHideDuration={3000}
      >
        <Alert
          onClose={() =>
            setShowSuccessToast({
              ...showSuccessToast,
              open: false,
            })
          }
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {showSuccessToast.message}
        </Alert>
      </Snackbar>
    </>
  );
}
